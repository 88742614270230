import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deliveryTime'
})
export class DeliveryTimePipe implements PipeTransform {

  transform(value: any): unknown {
     let minutes: any = Math.floor((value / (1000 * 60)) % 60);
     let hours: any = Math.floor((value / (1000 * 60 * 60)) % 24);

     hours = (hours < 10) ? "0" + hours : hours;
     minutes = (minutes < 10) ? "0" + minutes : minutes;

     return hours + ":" + minutes;
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArrayById',
    pure: false
})
export class FilterArrayByIdPipe implements PipeTransform {

    transform(value: Array<string | number>, filteringData: Array<{[key: string]: any}> = []): Array<{[key: string]: any}> {
        const data: Array<{[key: string]: any}> = []
        value.forEach((valueItem) => {
            filteringData.forEach((item,index) => {
                if (item.id === valueItem) {
                    data.push(item)
                }
            })
        })
        return data || []
    }

}

<div class="quick_filtration_container flex_align_center mt-10 m-rl-80">
    <div class="quick_filtration_container--filtration_item pointer"
         [ngClass]="{active: ((item.data | json) == (activeItem | json))}"
         *ngFor="let item of config?.actions?.quickFilter || []"
         (click)="makeFiltration(item)"
    >
        <span class="text">
            {{item.text | translate}}
        </span>
        <span class="count">
            {{item.count || 0}}
        </span>
        <div class="hidden_border"></div>
    </div>
</div>

import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ParseDateService {

    constructor() {
    }

    // toLocaleTime(time: any, format: string = ''): string {
    //   format = (format) ? format : 'DD.MM.YYYY HH:mm';
    //   const momentDate = momentTimezone.utc(time);
    //   return momentDate.format(format);
    // }

    getDiapasonRange(deliverOn: string, diapason: number): string {
        return moment(deliverOn).add(diapason, 'milliseconds').format('DD.MM.YYYY, HH:mm:ss');
    }

    changeTimeZone(date: any, format: string): string {
        const momentObj = moment.utc(date);
        if (format === 'DD.MM.YYYY HH:mm' || format === 'YYYY-MM-DDTHH:mm:ss') {
            momentObj.utcOffset('+03:00');
        }
        return momentObj.format(format) || ''
    }

    toDate(date: string): Date {
        return moment(date).toDate();
    }

    changeFormat(time: any, format: string): string {
        return moment(time).format(format);
    }

    checkDiffBetweenDates(startTime: any, endTime = undefined): number {
        const firstDate = moment(startTime);
        const secondDate = moment(endTime);
        return endTime ? secondDate.diff(firstDate) : firstDate.diff(secondDate);
    }

    toLocalTime(time: any, format: string = '', dontChangeOffset = false): string {
        format = (format) ? format : 'MM/DD/yy hh:mm';
        let momentDate = moment(time);
        momentDate.locale('ru');

        if (dontChangeOffset) {
            return momentDate.format(format)
        }

        if (format !== 'UTC') {
            return momentDate.add(-((new Date()).getTimezoneOffset()), 'minutes').format(format);
        } else {
            return momentDate.utc(true).format('MM/DD/yy hh:mm')
        }
    }


    checkLateDate(date: string | Date, secondDate?:string): boolean {
        const givenDate = moment(date, 'DD/MM/YYYY, HH:mm:ss');
        const secondDateString = secondDate ?  moment(secondDate, 'DD/MM/YYYY, HH:mm:ss') : moment();
        const isBefore = givenDate.diff(secondDateString)
        return isBefore <= 0
    }
}

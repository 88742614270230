import { Pipe, PipeTransform } from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {ShowMessageService} from "@services/messages/show-message.service";

@Pipe({
  name: 'showError',
  pure: true
})
export class ShowErrorPipe implements PipeTransform {
  subscription: any = null;
  constructor(private showMessageService: ShowMessageService) {
  }

  /**
   * Subscribe to input/select changes and show detecting formMessages if exist for current field
   *
   * @param form ( Reactive Form Group including all information and fields )
   * @param field ( Name of current field from Form group )
   * @param toChange ( Message object which containing message text, we can read message from this object )
   * @param submit ( Form is submitted or not, containing boolean or 'without', if current value is 'without' then we ignoring this property )
   */
  transform(form: UntypedFormGroup, field: string, toChange: { [key: string]: { message: string} } = {}, submit: any = null): boolean {
    if (this.subscription) { this.subscription.unsubscribe(); this.subscription = null; }
    this.subscription = form.get(field)?.valueChanges.subscribe(() => { this.showMessageService.detectAndShowMessage(form, field, toChange, submit) });
    this.showMessageService.detectAndShowMessage(form, field, toChange, submit)
    return true;
  }
}

<div class="modal_content flex_between" *ngIf="data">
    <div class="flex_between_align_center">
        <app-title-container
                [showData]="{
                     title:data.configs?.title,
                     subText:data.configs?.description,
                     icon:{
                      value: data.configs?.icon?.value,
                      type: data.configs?.icon?.type,
                      color: data.configs?.icon?.color
                     },
                     styles:{
                        classes:['text_container_for_dialog']
                     }
                }"
        ></app-title-container>
        <img class="close_image pointer" src="assets/images/icons/close.svg" *ngIf="data.configs?.closeButton"
             (click)="close()">
    </div>
    <div class="modal_content--content" [ngClass]="data?.configs?.marginTop === false ? '' : 'mt-10'">
        <ng-container #component></ng-container>
    </div>
    <div   [ngClass]="{'ml-auto mt-20 flex_between_align_center' : !data.configs?.button?.full}">
        <button class="main_buttons small_button cancel_btn"
                *ngIf="!data.configs?.cancelButton?.hidden"
                [ngClass]="{'mr-10':!data.configs?.cancelButton?.hidden}"
                (click)="close()">{{(data.configs?.cancelButton?.text || 'cancel') | translate}}</button>

        <app-button-with-loading (click)="save(data.configs?.button?.type)" *ngIf="!data.configs?.button?.hidden"
                                 [buttonClass]="(data.configs?.button?.class || 'orange_btn') + ' main_buttons small_button_big_padding flex_center_align_center'"
                                 [buttonText]="data.configs?.button?.text || 'save'"
                                 [submit]="pending"
                                 [disabled]="disabled || false"
        >
        </app-button-with-loading>
    </div>
</div>


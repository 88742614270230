import {Component, Input} from '@angular/core';

//Services
import {InputService} from "@services/components/input.service";

//Constants
import {AuthInputInterface} from "@intrerfaces/global/app-input.interface";

@Component({
  selector: 'app-auth-input',
  templateUrl: './auth-input.component.html',
  styleUrls: ['./auth-input.component.scss']
})
export class AuthInputComponent {

  @Input() inputData!: AuthInputInterface;

  @Input('errorBody') set getErrorMessage(errorBody: { message: string }) {
    if (!errorBody) {
      return;
    }
    this.errorBody = errorBody
  };

  errorBody!: any
  ngControl: any;

  constructor(private inputService: InputService) {
    this.ngControl = this.inputService.injectNgControl();
  }

}

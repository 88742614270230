import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NoopScrollStrategy} from "@angular/cdk/overlay";

// Components
import {
    StandardDialogComponent
} from "../../modules/admin/user/partials/components/dialogs/standard-dialog/standard-dialog.component";
import {
    CustomDialogComponent
} from "../../modules/admin/user/partials/components/dialogs/custom-dialog/custom-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        private dialogService: MatDialog
    ) {}

    rightSideDialogSettings = {
        autoFocus: false,
        panelClass: 'right-side-mat-dialog',
        position:{
            right: '0',
        },
        animation: {
            incomingOptions: {
                keyframes: [
                    { transform: "translateX(100%)" },
                    { transform: "translateX(0)" }
                ],
                keyframeAnimationOptions: { easing: "ease-in-out", duration:200 }
            },
            outgoingOptions: {
                keyframes: [
                    { transform: "translateX(0)" },
                    { transform: "translateX(100%)" }
                ],
                keyframeAnimationOptions: { easing: "ease-in-out", duration:500 }
            }
        }
    }

    openStandardDialog(
        component: any = null,
        styleConfigs: { width?: string, height?: string, minHeight?: string, maxHeight?: string, maxWidth?: string, disableClose?: boolean},
        data: any = null,
    ): MatDialogRef<any> {
        return this.dialogService.open(
            StandardDialogComponent,
            {
                ...styleConfigs,
                data: {configs: data, component},
                scrollStrategy: new NoopScrollStrategy(),
            })
    }

    openCustomDialog(
        component: any,
        styleConfigs: { width?: string, height?: string,maxWidth?: string, minHeight?: string, maxHeight?: string, position?: any, panelClass?: string} = {},
        data: any = null
    ): MatDialogRef<any> {
        styleConfigs.maxWidth = '95vw';
        return this.dialogService.open(
            CustomDialogComponent,
            {
                ...styleConfigs,
                data: {configs: data, component},
            })
    }
}

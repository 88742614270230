<form class="flex_wrap" [formGroup]="form">
    <div class="input_block w-100 mt-20 ml-10">
        <app-input formControlName="reason"
                   [inputData]="{
                     label:'shop.reason',
                     type: 'text',
                     placeholder: 'shop.reason'}"
        >
        </app-input>
    </div>
</form>

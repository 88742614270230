<div *ngIf="searchAccess" class="dropdownParent" [attr.data-name]="key">
    <div class="th--search_filter flex_align_center pl-10 pointer" (click)="openClose()">
        <img class="search_filter_icon" src="assets/images/icons/global/ic_filter.svg">
    </div>
    <div class="search_filter_dropdown" *ngIf="opened">
      <div class="search_filter_dropdown--content">
          <img src="assets/images/icons/global/ic_close.svg"
               class="search_filter_dropdown--content--close pointer"
               (click)="openClose()">

          <div class="app_input mt-20" (keyup)="addSearch()">
              <input type="text" placeholder="Search" [(ngModel)]="searchValue">
          </div>
      </div>
    </div>
</div>
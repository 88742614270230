import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router} from '@angular/router';
// Services
import {AuthService} from "@services/auth/auth.service";
import {RoleEnum} from "@intrerfaces/user/modules/role.enum";

@Injectable({
    providedIn: 'root'
})
export class MainAuthenticatedGuard implements CanLoad, CanActivate, CanActivateChild {

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {
    }

    /**
     * Helper function to check can Loading module and if module already loaded then check can Activate Module or not
     * @param route
     */
    canLoadOrActivateModule(route: Route | ActivatedRouteSnapshot): boolean | Promise<boolean> {
        const getRole = this.authService.getTokenAndRole();
        const type = route.data?.type ? route.data?.type : 'admin';
        if (getRole === RoleEnum.INVALID_USER) {
            return true
        }
        if (type == 'admin' && getRole !== RoleEnum.ROLE_ADMIN) {
            this.router.navigate(['/seller'])
            return false;
        } else if (type == 'seller' && getRole !== RoleEnum.ROLE_SELLER) {
            this.router.navigate(['/admin'])
            return false
        }
        return true;
    }

    /**
     * LOAD MODULE
     * If guard type is "admin" check token and it should be valid OR
     * If guard type is "login" check token it should be invalid
     * @param route
     */
    canLoad(route: Route): boolean | Promise<boolean> {
        return this.canLoadOrActivateModule(route);
    }

    /**
     * ACTIVATE COMPONENT
     * If guard type is "admin" check token and it should be valid OR
     * If guard type is "login" check token it should be invalid
     * @param route
     */
    canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
        return this.canLoadOrActivateModule(route);
    }

    /**
     * Activate MODULE childes
     * If guard type is "admin" check token and it should be valid OR
     * If guard type is "login" check token it should be invalid
     * @param route
     */
    canActivateChild(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
        return this.canLoadOrActivateModule(route);
    }
}

import {Component, Input} from '@angular/core';
import {AppTitleContainerInterface} from "@intrerfaces/global/app-input.interface";

@Component({
    selector: 'app-title-container',
    templateUrl: './title-container.component.html',
    styleUrls: ['./title-container.component.scss']
})
export class TitleContainerComponent {
    @Input() showData!: AppTitleContainerInterface;
}

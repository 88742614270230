import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Type,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

// Constants
import {HeadType, TableConfigs, TableData} from "@intrerfaces/user/components/table-component.interface";
import {GlobalSubscriptionResponse} from "@intrerfaces/user/components/subscribtions.interface";
import {UpdateSubscriptionTypesEnum} from "@intrerfaces/global/update-subscription-types.enum";
import {RequestMethodRoleEnum} from "@intrerfaces/user/request-methods/request-method-role.enum";

// Services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {ShowMessageService} from "@services/messages/show-message.service";
import {GlobalSubscriptionService} from "@services/subscribtions/global-subscription.service";
import {DialogService} from "@services/components/dialog.service";
import {CurrentStateInLocalstorageService} from "@services/state-management/state-in-local-storage.service";
import {NavigationService} from "@services/navigation/navigation.service";
import {ShopService} from "@services/shop/shop.service";
import {AuthService} from "@services/auth/auth.service";

// Components
import {FilterComponent} from "./filter/filter.component";
import {DialogDeleteComponent} from "./dialog-delete/dialog-delete.component";

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() config: TableConfigs | null = null;
    @Input() data: TableData | null = null;
    @Input() tableIndex: number | null = null;
    @Input() filterComponent!: Type<any>;
    @Input() speedFilterComponent!: Type<any>;
    @Output() readyEvent = new EventEmitter<void>();
    @Output() viwInit = new EventEmitter<void>();
    @Output() emitRequestEvent = new EventEmitter<void>();
    @Output() emitActionEvent = new EventEmitter<any>();
    @ViewChild('checkbox') checkboxHeader: ElementRef | undefined;
    @ViewChildren(TableComponent) subDataElements: QueryList<TableComponent> | undefined;
    @ViewChild('FilterComponent') baseFilterComponent!: FilterComponent;
    @ViewChild('tableParent') tableParent!: ElementRef;
    @ViewChild('filtrationResultContainer') filtrationResultContainer!: ElementRef;

    globalSubscription: Subscription | null = null;
    dropdownName: string = '';
    dropdowns: { [key: string]: boolean } = {
        tableUploadFiles: false,
        tableFilter: false
    };
    showTable = false;
    currentPage!: number;
    filtrationResult!: Array<{ key: string, value: string, title: string }>;
    tableHeight = '';
    defaultPageSize = 25;
    isSelected = false;
    selectedItemCount = 0;
    subjectForShops!: Subscription;
    shopId ?: number | null;
    firstInit = true;
    openFilterComponent = false;
    openSettingsComponent = false;
    passData = false;
    @HostListener('window:beforeunload')
    canDeactivate(): void {
        this.saveStateInLocalStorage();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data ? this.data.body : [], event.previousIndex, event.currentIndex);
        this.emitActionEvent.emit({type: 'dragAndDrop'})
    }

    constructor(
        private router: Router,
        private dialogService: DialogService,
        private requestService: RequestMethodsService,
        private showMessageService: ShowMessageService,
        private globalSubscriptionService: GlobalSubscriptionService,
        private stateManagementService: CurrentStateInLocalstorageService,
        private navigationService: NavigationService,
        private shopService: ShopService,
        private authService: AuthService
    ) {
        this.globalSubscription = this.globalSubscriptionService.getMessage().subscribe((response: GlobalSubscriptionResponse) => {
            if (response.type === 'dropdown') {
                this.dropdownName = response.message;
                for (let dropdown in this.dropdowns) {
                    if (dropdown !== this.dropdownName) {
                        this.dropdowns[dropdown] = false;
                    }
                }
            } else if (response.type === UpdateSubscriptionTypesEnum.GET_SHOPS) {
                if (!this.config?.actions?.detectShopChange) {
                    return;
                }
                if (this.firstInit) {
                    return;
                }
                this.setFilterDataShopId();
                this.readyEvent.emit();
            } else if (response.type === UpdateSubscriptionTypesEnum.STORAGE_READY) {
                this.detectShopInStorage();
            }
        });
    }

    ngOnInit(): void {
        if (this.data?.pagination && !this.data.pagination.currentPage) {
            this.data.pagination.currentPage = 1;
            this.currentPage = 1;
            this.data.pagination.pageSize = this.defaultPageSize;
            this.data.pagination.sortDirection = "DESC"
            this.data.pagination.sortEnum = "ID"
        }
        this.getActiveState();
        if (!this.config?.actions?.detectShopChange) {
            this.readyEvent.emit();
        } else {
            this.firstInit = false;
            this.detectShopInStorage();
        }
    }


    detectShopInStorage(): void {
        const hasShopId = this.shopService.getShopIdFromLocalStorage();
        if (hasShopId) {
            this.setFilterDataShopId();
            this.emitRequestEvent.emit();
        }
        // if (this.config?.actions?.filterBar) {
        //     this.config.actions.filterBar.addButton = !!hasShopId;
        // }
    }

    setFilterDataShopId(): void {
        this.shopId = this.shopService.getShopIdFromLocalStorage();
        if (this.data?.pagination?.filter) {
            this.data.pagination.filter.shopIds = [this.shopId];
        }
    }


    ngAfterViewInit(): void {
        setTimeout(() => {
            this.showTable = true;
            this.viwInit.emit()
        }, 10)
    }

    /**
     * Change current page parameter and emit to make request
     * @param page
     */
    changePage(page: number): void {
        if (!page || page <= 0) {
            this.showMessageService.showMessageByVariable('pageCanNotBe')
            return;
        }

        if (this.data?.pagination?.totalPages && page > this.data.pagination.totalPages) {
            this.showMessageService.showMessageByVariable('pageCanNotBiggerThenTotal')
            return;
        }

        if (this.data?.pagination && this.data.pagination.currentPage !== page) {
            this.isSelected = false;
            this.data.pagination.currentPage = page;
            this.emitRequestEvent.emit();
        }
    }

    changePageSize(): void {
        if (this.data?.pagination) {
            this.data.pagination.currentPage = 1
            this.emitRequestEvent.emit();
        }
    }

    checkUncheckRowEvent(value: boolean, index: number | undefined): void {
        const subTables = this.subDataElements?.toArray();
        const subTableExists = this.config?.actions?.dropDownRowForSubData;
        if (subTables && subTableExists && (index !== undefined)) {
            const currentSubTable = subTables.find((subTable: TableComponent) => subTable.tableIndex === index);
            currentSubTable?.checkAllRows(value, true);
        }
    }

    makeFastFilter(): void {

        if (this.data?.pagination?.currentPage) {
            this.data.pagination.currentPage = 1;
        }

        this.emitRequestEvent.emit();
    }

    toggleParentCheckbox(value: boolean): void {
        if (!this.checkboxHeader) {
            return;
        }
        this.checkboxHeader.nativeElement.checked = value;
    }

    checkAllRows(value: boolean, fake = false): void {
        setTimeout(() => {
            if (fake) {
                this.toggleParentCheckbox(value);
            }
        }, 50)

        if (!value) {
            this.isSelected = false;
        }

        this.selectedItemCount = 0;
        const subTables = this.subDataElements?.toArray();
        const subTableExists = this.config?.actions?.dropDownRowForSubData;
        this.data?.body?.forEach((item: any, index: number) => {
            if (value && this.data?.pagination?.totalElementsCount) {
                this.selectedItemCount = this.data?.pagination?.totalElementsCount
            }
            item.checked = value;
            if (subTables && subTableExists) {
                const currentSubTable = subTables.find((subTable: TableComponent) => subTable.tableIndex === index);
                if (currentSubTable) {
                    currentSubTable?.checkAllRows(value, true)
                }
            }
        });
    }

    toggleSelectedAll(isSelected: boolean): void {
        this.isSelected = isSelected
        this.checkAllRows(this.isSelected, true);
        this.emitActionEvent.emit({type: 'ALL_SELECTED'});
    }


    isSelectedAll(): boolean {
        return this.isSelected
    }

    getAllCheckedRowsIds(): Array<{ parentId: number, subIds?: Array<number> }> {
        const subTables = this.subDataElements?.toArray();
        const subTableExists = this.config?.actions?.dropDownRowForSubData;
        const checkedItems: Array<{ parentId: number }> = [];
        this.data?.body?.forEach((item: any, index: number) => {
            if (item.checked) {

                const resultForRow: any = {parentId: item.id};
                if (subTableExists && subTables) {
                    const currentSubTable = subTables.find((subTable: TableComponent) => subTable.tableIndex === index);
                    if (currentSubTable) {
                        resultForRow.subIds = currentSubTable.getAllCheckedRowsIds()?.map((selected: any) => selected.parentId);
                    } else {
                        const subDataCheckedIds: Array<number> = [];
                        let returnValue: any = item;
                        this.config?.actions?.dropDownRowForSubData?.subValuesKey.forEach((key: string | number) => {
                            if (!returnValue) {
                                return;
                            }
                            returnValue = returnValue[key];
                        })

                        const subData = returnValue;

                        if (!subData) {
                            return;
                        }

                        subData.forEach((subDataItem: any) => {
                            subDataCheckedIds.push(subDataItem.id);
                        })
                        resultForRow.subIds = subDataCheckedIds;
                    }
                }
                checkedItems.push(resultForRow);
            }
        });

        return checkedItems;
    }

    /**
     * Table Actions  remove/edit etc...
     * @param type
     * @param data
     */
    emitAction(type: string, data: any = {}, rowIndex?: number): void {
        if (type === 'TRASH' || type === 'UN_ASSIGN') {
            if (this.config?.actions?.remove?.type === 'local') {
                this.emitActionEvent.emit({type: 'removeLocally', data});
            } else {
                const param = this.data?.settings?.header?.delete?.data?.withParam
                param ? this.removeRowAfterAccept(data[param]) : this.removeRowAfterAccept(data);
            }
        } else if (type === 'EDIT' && this.config?.actions?.edit?.routeLink) {
            this.navigationService.navigateToRelativeRoute(this.config.actions.edit.routeLink, data.id)
        } else if (type === 'singlePage' && this.config?.actions?.singlePage?.routeLink) {
            if (this.config.actions.singlePage.withParam?.length) {
                let id: any = 0;
                this.config.actions.singlePage.withParam.forEach((key: string) => {
                    id = (id) ? id[key] : data[key];
                })
                if (this.config?.actions.singlePage.forceNavigation) {
                    this.router.navigate([this.config.actions.singlePage.routeLink + '/' + id]);
                } else {
                    this.navigationService.navigateToRelativeRoute('', id)
                }
            } else {
                this.navigationService.navigateToRelativeRoute(this.config.actions.singlePage.routeLink, data.id)
            }
        } else if ((type === 'DROPDOWN')) {
            this.showSubDataForRow(data.row, data.table)
        } else if (type === 'CHECKBOX') {
            if (!data.value) {
                this.toggleParentCheckbox(false);
                this.isSelected = false;
                this.selectedItemCount--
            } else {
                this.selectedItemCount++
                if (this.getAllCheckedRowsIds().length === this.data?.body.length) {
                    this.toggleParentCheckbox(true);
                }
            }
            this.checkUncheckRowEvent(data.value, rowIndex)
        } else if ((type === 'EDIT_SINGLE') && this.config?.actions?.singlePage?.routeLink) {
            this.navigationService.navigateToRelativeRoute(this.config.actions.singlePage.routeLink, data.id)
        } else if (type === 'TRASH_SINGLE' && this.config?.endpointName) {
            const dialog = this.dialogService.openStandardDialog(DialogDeleteComponent, {width: '458px'}, {
                title: 'confirmationForDelete',
                button: {
                    text: 'confirm'
                },
            })
            dialog.afterClosed().subscribe((response: any) => {
                if (!response || !this.config?.endpointName) {
                    return;
                }
                this.requestService.delete(`${this.config.endpointName}/${data.id}`, RequestMethodRoleEnum.seller).subscribe(() => {
                    this.emitRequestEvent.emit();
                    this.emitActionEvent.emit({type: 'deleted'})
                })
            })

        }

        this.emitActionEvent.emit({type, data});
    }

    // Remove Block
    /**
     * Open dialog to accept removing, after accepting make call removeRow function to make request
     * @param data
     */
    removeRowAfterAccept(data: any): void {
        const acceptDialog = this.dialogService.openStandardDialog(
            null,
            {width: '40vw'},
            {title: 'Confirm Deletion', button: {text: 'Confirm', class: 'green_btn'}}
        )
        acceptDialog.afterClosed().subscribe((answer: any) => {
            if (!answer) return;
            const configType = this.config?.actions?.remove?.type;
            if (configType === 'byRequest' || configType === undefined) {
                // else if configured remove action type is "byRequest" call removeRow function
                this.removeRow([data.id]);
            }
        });
    }

    removeRow(ids: Array<number>): void {
        let request = (this.data?.settings?.header?.trash?.format === 'multiple') ?
            this.requestService.delete(`${(this.config?.endpointName || '')}?ids=${ids}`) :
            this.requestService.delete((this.config?.endpointName || '') + '/' + ids[0])

        if (ids.length === 1) {
            request.subscribe(() => {
                this.showMessageService.showMessageByVariable('remove', 'success');
                this.emitRequestEvent.emit();
            })
        }
    }

    // End of remove block

    // Filter Block

    handleFiltrationEvent(): void {
        if (this.data?.pagination) {
            this.data.pagination.currentPage = 1;
            this.emitRequestEvent.emit();
        }
    }

    removeFilter(key: string) {
        if (this.data?.pagination?.filter) {
            this.baseFilterComponent?.removeFilterByKey(key)
        }
    }

    openTableFilterDropDownDialog(type: string, result?: any) {
        if (type === 'OPEN_FILTER') {
            this.openFilterComponent = true;
        }else if (type === 'CLOSE_FILTER') {
            if (result == true || result?.length == 0) {
                this.openFilterComponent = false;
                this.handleFiltrationEvent();
            } else {
                this.filtrationResult = result;
                this.openFilterComponent = false;
            }
        }
    }

    // End of filter block

    // ************ SORTING SEARCH AND FILTRATION *******************
    /////////////////////////////////////////////////////////////////////////////////////

    //Settings drop down block
    openTableSettingsDropDownDialog() {
        this.openSettingsComponent = true
    }
    closeModal(): void{
        this.openSettingsComponent = false;
        this.openFilterComponent = false;
    }

    //End of drop down block

    // Change sort direction and enum
    makeSorting(head: HeadType): void {
        const key: string = head.key.toString();
        if (!(this.data && this.data.pagination && this.data.settings?.sorting && this.data.settings.sorting[key])) {
            return;
        }
        const pagination = this.data.pagination;
        pagination.sortEnum = this.data.settings.sorting[key];
        pagination.sortDirection = (pagination.sortDirection === 'DESC') ? 'ASC' : 'DESC';
        this.emitRequestEvent.emit();
    }

    // Make search by column
    columnSearchEventHandler(data: { key: string, value: string }): void {
        if (this.data && this.data.pagination) {
            if (!this.data.pagination.search) {
                this.data.pagination.search = {};
            }

            this.data.pagination.search[data.key] = data.value;
            this.emitRequestEvent.emit();
        }
    }

    tableSearch(value: string): void {
        if (this.data && this.data.pagination)
            this.data.pagination.searchBy = value;
        this.emitRequestEvent.emit();
    }

    // ************  END OF SORTING SEARCH AND FILTRATION *******************
    /////////////////////////////////////////////////////////////////////////////////////

    // ********** Dropdown row to show sub data *****
    showSubDataForRow(row: { [key: string]: any }, dropdownElement: any): void {
        dropdownElement.setAttribute('data-opened', true);
        dropdownElement.hidden = !dropdownElement.hidden;
        this.subTableReadyEvent(row, dropdownElement);
    }

    subTableReadyEvent(row: { [key: string]: any }, dropdownElement: any): void {
        if (!dropdownElement.hidden) {
            const subTables = this.subDataElements?.toArray();
            const subTableExists = this.config?.actions?.dropDownRowForSubData;
            const rowIndex = this.data?.body.findIndex((item: any) => item.id === row.id);
            if (subTables && subTableExists && (rowIndex !== undefined)) {
                const subTable = subTables.find((subTable: TableComponent) => subTable.tableIndex === rowIndex);
                if (subTable) {
                    subTable.checkAllRows(row.checked, true)
                }
            }
        }
    }

    // ********** END of Dropdown row to show sub data *****

    // State management start

    activateAllColumns(): void {
        this.data?.header.forEach(object => object.status = true)
    }

    getActiveState(): void {

        if (!this.config?.tableEnum) {
            return;
        }
        const currentState = this.stateManagementService.getStateFromLocalStorageForTable(this.config.tableEnum);

        if (currentState?.settings) {
            const filterSystemActions = currentState?.settings.filter((item: any) => item.id !== 0);

            if (!filterSystemActions.find((item: any) => item.status)) {
                this.activateAllColumns();
            } else {
                currentState?.settings.forEach((item: any, index: number) => {
                    if (!this.data?.header || !this.data.header[index]) {
                        return;
                    }
                    this.data.header[index].status = item.status
                })
            }

        } else {
            this.activateAllColumns();
        }

        if (currentState?.pagination && this.data?.pagination) {
            this.data.pagination = {
                ...this.data?.pagination,
                ...currentState.pagination
            }
        }
    }

    saveStateInLocalStorage(): void {
        if (!this.config?.tableEnum) {
            return;
        }
        const settings: any = []
        this.data?.header.forEach((item: any) => {
            settings.push(
                {
                    id: item.id,
                    status: item.status || false
                }
            )
        })

        if (!this.authService.getToken()) {
            this.stateManagementService.removeAll()
            return;
        }
        this.stateManagementService.saveStateInLocalStorageForTable(this.config.tableEnum, {
            pagination: this.data?.pagination || {},
            settings: settings || [],
            quickFilter: this.data?.pagination?.quickFilter || {}
        })

    }

    // End of state management

    ngOnDestroy(): void {
        if (this.globalSubscription) {
            this.globalSubscription.unsubscribe();
        }
        if (this.subjectForShops) {
            this.subjectForShops.unsubscribe();
        }
        this.saveStateInLocalStorage();
    }
}

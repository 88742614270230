import {Injectable} from '@angular/core';
import {SuccessMessages, ErrorMessages} from "./messages";
import {ToastrService} from "ngx-toastr";
import {UntypedFormGroup} from "@angular/forms";
import {formMessages} from "./form-messages";

@Injectable({
    providedIn: 'root'
})
export class ShowMessageService {

    constructor(private toastrService: ToastrService) {
    }

    /**
     * Show Message from parameter and by message type error / success
     *
     * @param message (Custom message)
     * @param type ( Message type error | success )
     */
    showCustomMessage(message: string, type: string = 'error'): void {
        if (!message) {
            if (type === 'error') {
                this.toastrService.error(ErrorMessages['global']);
            }
            return;
        }
        if (type === 'error') {
            this.toastrService.error(message);
        } else {
            this.toastrService.success(message);
        }
    }

    /**
     *  Show message by type from constant formMessages by variable
     *
     * @param variable ( name of constant from formMessages file)
     * @param type ( error / success )
     */

    showMessageByVariable(variable: string, type: string = 'error', customPart = ''): void {
        if (type === 'error') {
            this.showCustomMessage(ErrorMessages[variable] + customPart, type)
        } else {
            this.showCustomMessage(SuccessMessages[variable] + customPart, type);
        }
    }

    /**
     * Helper function which checking the error fields from form group and getting
     * the corresponding message for this field from formMessages file
     *
     * @param form
     * @param field
     * @param toChange
     * @param submit
     */
    detectAndShowMessage(form: UntypedFormGroup, field: string, toChange: { [key: string]: { message: string } } = {}, submit: any = null): void {
        const continueChecking = (submit || submit === 'without');
        if (!continueChecking) { return; }
        const errorsObject = form.get(field)?.errors;
        if (errorsObject) {
            const firstValidationRuleKey = Object.keys(errorsObject)[0];
            if (firstValidationRuleKey) {
                toChange[field].message = this.getMessageForFormField(field, firstValidationRuleKey) || '';
            }
        } else {
            toChange[field] = {message: ''}
        }
    }

    getMessageForFormField(field: string, rule: string = 'required'): string {
        if (!formMessages[field]) { return ''; }
        return formMessages[field][rule]
    }
}

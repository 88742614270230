import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatSelect} from "@angular/material/select";
import {MatOption} from "@angular/material/core";
//Services
import {TranslateService} from "@ngx-translate/core";
import {InputService} from "@services/components/input.service";
//Constants
import {SelectWithScrollConfigInterface} from "@intrerfaces/user/components/select-with-scroll-config.interface";
import {Observable} from "rxjs";

@Component({
  selector: 'app-mat-select-with-scroll',
  templateUrl: './mat-select-with-scroll.component.html',
  styles: [`
    .select-all {
      margin: 8px 17px;
    }

    .label {
      line-height: 16px;
      position: absolute;
      top: 11px;
      left: 16px;
      color: var(--text-gray-color);

      &.color_white {
        color: var(--gray-input);
      }
    }
  `]
})
// TODO refactor html and ts part
export class MatSelectWithScrollComponent implements AfterViewInit {
  @ViewChild('matSelect') matSelect!: MatSelect;
  @Input() className!: string;
  @Input() configs!: SelectWithScrollConfigInterface;
  @Output() selectionChange = new EventEmitter<any>();
  @Output() paginationDataEmitter = new EventEmitter<any>();
  @ViewChild('allSelected') allSelected!: MatSelect;

  selectedAll = false;
  data: Array<any> = [];
  paginationData: any = {current: 1, last: 0};
  ngControl: any;
  constructor(private translate: TranslateService,
              private inputService: InputService
  ) {
    this.ngControl = this.inputService.injectNgControl();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getData();
    },10)
  }

  getData(searchText?: string, fromSearch?: boolean): void {
    if (!this.configs) {return;}

    if ((typeof this.configs.paginate) !== 'function') {
      this.data = this.configs.paginate;
      return;
    }

    if (fromSearch) {this.paginationData.current = 1}

    const functionToDo = ((this.configs.search) ?
        this.configs.paginate(this.paginationData.current, searchText) :
        this.configs.paginate(this.paginationData.current)) as Observable<any>;

    functionToDo?.subscribe((data: Array<any>) => {
          this.data = (fromSearch) ? data : [...this.data, ...data];

          this.paginationDataEmitter.emit(this.data)
            if (!data.length) {
              this.paginationData.last = this.paginationData.current;
              return;
            }
            this.paginationData.current += 1;
            this.paginationData.last += 1;
           // this.checkAndSetValue();
        })
  }

  toggleAllSelection(): void {
    if (this.selectedAll) {
      this.matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      this.matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick(): void {
    let newStatus = true;
    this.matSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.selectedAll = newStatus;
    this.selectionChange.emit();
  }

  registerPanelScrollEvent(element: MatSelect): void {
    const panel = element.panel.nativeElement;
    panel.addEventListener('scroll', (event: any) => this.loadDataOnScroll(event));
  }

  loadDataOnScroll(event: any): void {
    if (this.configs.paginateOnScroll === false) {
      return;
    }
    const pos = (event.target.scrollTop || event.target.scrollTop) + event.target.offsetHeight;
    const max = event.target.scrollHeight;
    if ((pos + 1) >= max) {
      if (this.paginationData.current === this.paginationData.last) { return; }
      this.getData();
    }
  }

  setPlaceholderDefault(id: string, text: string): void {
    if (!id || !text) { return;}
    const elem = document.getElementById(id)
    if (!elem?.childNodes[0]?.childNodes[0]?.textContent) {
      return;
    }
    elem.childNodes[0].childNodes[0].textContent = this.translate.instant(text);
  }

  reloadData(force = false): void{
    if(force){
        this.paginationData.current = 1
        this.paginationData.last = 0;
        this.data = [];
        this.getData();
    }
  }


  // checkAndSetValue(): void {
  //   if (this.ngControl && this.ngControl.value) {
  //     const currentValue = this.ngControl.value;
  //     const matchedOption = this.data.find(option =>
  //         this.configs.valueByKey.some(key => option[key] === currentValue)
  //     );
  //     if (matchedOption) {
  //       this.ngControl.control.patchValue(currentValue, { emitEvent: false });
  //     } else {
  //       this.getData();
  //     }
  //   }
  // }

}



import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-clear-value',
  template: `<img [className]="className ? '' : 'standard'" 
                  class="pointer"
                  [ngClass]="className" 
                  (click)="reset.emit()" 
                  src="assets/images/icon/ic_close_red.svg"
              >`,
  styles: [`
      img {
        position: absolute;
        width: 11px;
        height: 11px;
      }
      .range_date {
        right: -57px;
        top: 5px;
      }
      
      .standard {
        right: -17px;
        bottom: 15px;
      }
  `]
})
export class ClearValueComponent {
  @Input() className!: string;
  @Output() reset = new EventEmitter<any>();
}

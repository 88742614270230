import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getArrayByKeyFromObject'
})
export class GetArrayByKeyFromObjectPipe implements PipeTransform {

  transform(
      keys: string | Array<string | number>,
      data: any,
      withParamsForArray?: string | Array<string> ,
  ): Array<string> {
    const names: Array<string> = []
    if (typeof withParamsForArray === 'string') {
      data[keys.toString()].forEach((item: any) => {
        names.push(' ' + item[withParamsForArray.toString()])
      })
    }
    return names

  }

}

import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
//Services
import {AuthService} from "@services/auth/auth.service";

//Constants
import {RequestMethodRoleEnum} from "@intrerfaces/user/request-methods/request-method-role.enum";
import {RoleEnum} from "@intrerfaces/user/modules/role.enum";

//Animations
import {slideRightFilterComponentAnimation} from "../../../../../../../animations/filter_dropdown.animation";

@Component({
  selector: 'app-table-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations:[slideRightFilterComponentAnimation]
})
export class FilterComponent implements AfterViewInit {
  @ViewChild('component', {read: ViewContainerRef}) componentContainer: ViewContainerRef | undefined;
  @Input() data!: {filtrationBody:any,filterComponent:any,optionalData:any};
  @Output() closeModal = new EventEmitter<any>();
  @Input() passData!:boolean;
  component: ComponentRef<any> | undefined;
  role!: RequestMethodRoleEnum;

  constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private authService: AuthService
  ) {}

  ngAfterViewInit(): void {
    if (!this.data.filterComponent) { return; }
    this.role = this.authService.getTokenAndRole() === RoleEnum.ROLE_ADMIN ? RequestMethodRoleEnum.admin : RequestMethodRoleEnum.seller;
    setTimeout(() => {
      this.component = this.componentContainer?.createComponent(
          this.componentFactoryResolver.resolveComponentFactory(this.data.filterComponent)
      )

      if (this.component) {
        this.component.instance.filtrationBody = this.data.filtrationBody;
        if (this.data.optionalData) {
          this.component.instance.optionalData = this.data.optionalData;
        }
      }
    },1)
  }

  removeFilterByKey(key: string): void {
    this.component?.instance.resetFormValue(key);
    delete this.data.filtrationBody[key];
    this.save();
  }
  save(): void {
    this.component?.instance.setFormDataToPaginationBody();
    setTimeout(() => {
      this.closeModal.emit(true)
    }, 300)
  }

  removeFilter(): void {
    this.component?.instance.form.reset();
    Object.keys(this.component?.instance.form.value).forEach((key: string) => {
      if (this.role === RequestMethodRoleEnum.seller && key === 'shopIds') {
        return;
      }
      delete this.data.filtrationBody[key];
    });
    this.closeModal.emit([]);
  }

  close():void{
    this.closeModal.emit(false);
  }

}

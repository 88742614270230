<div class="flex_column_align_center"
     [ngStyle]="showData?.styles?.customStyle || {}"
     [ngClass]="showData?.styles?.classes || []"
>
    <div class="flex_align_center">
        <div *ngIf="showData.icon?.value" class="icon flex_center_align_center mr-10" [ngStyle]="{backgroundColor:showData.icon?.color}">
            <ng-container *ngIf="showData.icon?.type == 'image'; else text">
                <img [src]="'assets/images/icons/'+showData.icon?.value">
            </ng-container>
            <ng-template #text>
                <span>
                {{showData.icon?.value}}
                </span>
            </ng-template>
        </div>
        <div class="main_text" *ngIf="showData.title">{{showData.title | translate}}</div>
    </div>
    <div class="sub_text  middle_text" *ngIf="showData.subText">{{showData.subText | translate}}</div>
</div>

export interface TokenInterface {
    accessToken: string,
    refreshToken: string,
    expiresIn: number,
    tokenType: string,
    userId: number,
    roles: Array<string>,
    phoneNumber: string
}

export enum CheckToken {
    INVALID,
    VALID,
    PENDING
}

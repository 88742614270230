import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ErrorPageComponent} from "./error-page/error-page.component";

const components = [ErrorPageComponent];
@NgModule({
  declarations: components,
  imports: [
    CommonModule
  ],
  exports: components
})
export class GlobalComponentsModule { }

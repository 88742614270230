<div class="terms-condition-block flex_column w-80">
    <p class="sub_title_text"> Публичная оферта на оказание услуг сервиса Клик Маркет </p>

    <p class="sub_title_text">Настоящая оферта является предложением Общества с ограниченной ответственностью «Драйв»
        (ИНН 1659056744; ОГРН: 1051637013823; адрес: 420059, Россия, Республика Татарстан, г Казань, ул. Павлюхина, 89),
        именуемого далее Клик Маркет, заключить Договор на оказание услуг сервиса Клик Маркет на следующих ниже
        условиях:</p>

    <strong class="sub_title_text">1. Термины и определения</strong>

    <p class="middle_text">
        <span class="title_normal_text">1.1.</span>В настоящей Публичной оферте на оказание услуг сервиса Клик Маркет используются следующие
        термины и/или определения понятий:
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Публичная оферта на оказание услуг сервиса Клик Маркет (Оферта) </span>
        <span class="title_normal_text">
            – настоящий документ, размещенный в сети Интернет по адресу:
            <a href="https://admin.click-market.ru/">https://admin.click-market.ru/.</a>
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Сервис Клик Маркет (сервис) </span>
        <span class="title_normal_text">
           – программа для ЭВМ, представляющая собой программный комплекс, включающий веб-сайт и мобильные приложения, правообладателем которого является Клик Маркет, предназначенный для покупки и продажи товаров, выполнения заказов.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Мобильные приложения Клик Маркет </span>
        <span class="title_normal_text">
            -  программы для ЭВМ, предназначенные для использования пользователей на мобильных устройствах с операционными системами iOS и Android, доступные для скачивания по адресу:
            <a href="https://dev.click-market.ru/">https://dev.click-market.ru/</a>
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Сайт </span>
        <span class="title_normal_text">
           веб-сайт <a href="https://click-market.ru"> https://click-market.ru/</a>  в сети Интернет, включая все его страницы и поддомены.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Пользователь </span>
        <span class="title_normal_text">
           – право- и дееспособное физическое лицо, индивидуальный предприниматель или юридическое лицо, использующее Сервис с целью приобретения Товара или заказывающее Доставку Товара.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Получатель </span>
        <span class="title_normal_text">
            – третье лицо, указанное Пользователем в качестве получателя Товара, при условии, что Пользователь не является получателем Товара.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Товар </span>
        <span class="title_normal_text">
          – объект купли-продажи (вещь), не изъятый из гражданского оборота и не ограниченный в нем, являющийся предметом договора купли-продажи/поставки, заключаемого с использованием Сервиса. Информация о Товарах размещена на Сервисе.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Продавец </span>
        <span class="title_normal_text">
          – индивидуальный предприниматель или юридическое лицо, осуществляющее деятельность по продаже Товаров на Сервисе.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Личный кабинет </span>
        <span class="title_normal_text">
          – раздел на Сервисе, в котором Продавец может управлять своими Товарами, просматривать историю Заказов, просматривать информацию о суммах денежных средств, которые были получены от Пользователей, о суммах, которые будут переведены Продавцу, о суммах, которые будут начислены Сервису, в качестве вознаграждения.
        </span>
    </p>

    <p class="middle_text">
        <span class="sub_title_text">Заказ </span>
        <span class="title_normal_text">
          – осуществление Пользователем действий, направленных на заключения договора купли-продажи и/или договора Доставки Товара, путем оформления заказа на Сервисе.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Промокод Сервиса </span>
        <span class="title_normal_text">
          – буквенно-цифровой код, имеющий свои условия и сроки, выпущенный Сервисом, подтверждающий внесение авансового платежа и дающий право Пользователю/Получателю в течении срока действия Промокода Сервиса на приобретение товара Продавца на сумму, эквивалентную номиналу Промокода Сервиса. Денежные средства за использование Промокода Сервиса Пользователем или Получателем компенсирует Клик Маркет Продавцу.
        </span>
    </p>

    <p class="middle_text">
        <span class="sub_title_text">Доставка  </span>
        <span class="title_normal_text">
          – услуга доставки Товара, приобретенного Пользователем на Сервисе. Договор доставки заключается между Пользователем и Продавцом, посредством оформления Заказа.
        </span>
    </p>

    <p class="middle_text">
        <span class="sub_title_text">Доставщик  </span>
        <span class="title_normal_text">
          – лицо, осуществляющее Доставку Товара, приобретенного на Сервисе.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Служба поддержки Сервиса </span>
        <span class="title_normal_text">
          – служба, в которую Продавец может обратиться за оказанием технической поддержки по решению возникшей проблемы, а также за получением дополнительной информации по интересующему вопросу. Обращения в службу поддержки Сервиса могут быть направлены по электронной почте
         <a href="support@click-market.ru">support&#64;click-market.ru</a>
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Договор </span>
        <span class="title_normal_text">
          – возмездный договор между Продавцом и Клик Маркет, который заключается посредством акцепта Оферты.
         <a href="support@click-market.ru">support&#64;click-market.ru</a>
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Материалы </span>
        <span class="title_normal_text">
         – предоставляемые Продавцом информационные материалы о Товаре, Продавце и условиях доставки, удовлетворяющие требованиям, указанным в настоящей Оферте.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Модерация </span>
        <span class="title_normal_text">
         – процесс проверки Клик Маркетом информации, которую предоставил Продавец.
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Условия использования сервиса Клик Маркет </span>
        <span class="title_normal_text">
         – документ, размещенный в сети Интернет по адресу:
            <a href="https://admin.click-market.ru/">https://admin.click-market.ru/.</a>
        </span>
    </p>
    <p class="middle_text">
        <span class="sub_title_text">Карточка товара </span>
        <span class="title_normal_text">
         – размещаемые Правообладателем на Сервисе информационные материалы о реализуемом Правообладателем Товаре, содержащие фотографии Товара, название, состав, размеры и описание Товара.
        </span>
    </p>

    <strong class="sub_title_text">2. Предмет Договора</strong>

    <p class="middle_text">
        <span class="title_normal_text">2.1.</span> В силу ст. 421 ГК РФ Договор является смешанным, и включает в себя
        элементы договора возмездного оказания услуг, а также элементы агентского договора.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">2.2.</span> Клик Маркет с использованием Сервиса оказывает в сети Интернет услуги
        по размещению Товаров Продавца на Сервисе и заключению договоров купли-продажи Товаров.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">2.3.</span>Клик Маркет может оказывать Продавцу иные дополнительные услуги, не
        указанные в п 2.2. Оферты. Условия оказания иных дополнительных услуг оговариваются Сторонами отдельно.
    </p>

    <strong class="sub_title_text">3. Условия оказания услуг </strong>

    <p class="middle_text">
        <span class="title_normal_text">3.1.</span> Договор считается заключенным с момента акцепта Продавцом Оферты на
        изложенных в ней условиях.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">3.2.</span> Принимая условия Оферты, Продавец также принимает условия:
    </p>
    <p class="middle_text">
        - Соглашения об обработке персональных данных, размещенного в сети Интернет по адресу: <a
            href="https://admin.click-market.ru/seller">https://admin.click-market.ru/seller</a>.;
    </p>
    <p class="middle_text">
        - Политика в отношении файлов cookies, размещенной в сети Интернет по адресу: <a
            href="https://admin.click-market.ru/seller">https://admin.click-market.ru/seller</a>.;
    </p>
    <p class="middle_text">
        - Условий использования сервиса Клик Маркет, размещенного в сети Интернет по адресу: <a
            href="https://admin.click-market.ru/seller">https://admin.click-market.ru/seller</a>.
    </p>

    <p class="middle_text">
        <span class="title_normal_text">3.3.</span> Для регистрации на Сервисе Продавец указывает следующую информацию:
    </p>
    <p class="middle_text">
        - фирменное наименование;
    </p>
    <p class="middle_text">
        - юридический, почтовый и фактический адрес;
    </p>
    <p class="middle_text">
        - режим работы Продавца;
    </p>
    <p class="middle_text">
        - идентификационный номер налогоплательщика;
    </p>
    <p class="middle_text">
        - описание магазина;
    </p>
    <p class="middle_text">
        - стоимость и время Доставки Товара в пределах города;
    </p>
    <p class="middle_text">
        - банковские реквизиты для осуществления расчетов;
    </p>
    <p class="middle_text">
        - контактные данные сотрудников, ответственных за исполнение Договора;
    </p>

    <p class="middle_text">
        <span class="title_normal_text">3.4.</span> При регистрации на сервисе Продавец обязан указывать достоверную
        информацию. Клик Маркет не несет ответственности за содержание и достоверность информации, предоставленной
        Продавцом, а продавец не вправе предъявить Клик Маркет какие-либо претензии и/или требовать возмещения
        каких-либо убытков в связи с указанием Продавцом недостоверной информации.
    </p>

    <p class="middle_text">
        <span class="title_normal_text">3.5.</span> Клик Маркет не редактирует информацию о Продавце, однако Служба
        поддержки Сервиса вправе проверить корректность предоставленных данных, в том числе путем запроса
        соответствующих документов.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">3.6.</span> В течении 10 (Десяти) дней с момента выполнения Продавцом всех
        действий, необходимых для регистрации на Сервисе, Служба поддержки Сервиса осуществляет Модерацию. Информации,
        предоставленной Продавцом. После Модерации Продавцу в Личном кабинете приходит уведомление об успешной
        регистрации на Сервисе или об отказе в регистрации. В случае отказа в регистрации Служба поддержки Сервиса
        вправе указать Продавцу на необходимость осуществить дополнительные действия, после которых Клик Маркет будет
        проведена повторная Модерация.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">3.7</span> Не признается надлежащим акцептом Оферты совершение действий,
        предусмотренных Разделом 3 Оферты, лицом:
    </p>
    <p class="middle_text">
        - в отношении которого в едином государственном реестре юридических лиц, в едином государственном реестре
        индивидуальных предпринимателей или в реестре физических лиц, применяющих специальный налоговый режим «Налог на
        профессиональный доход», отсутствуют сведения о государственной регистрации;
    </p>

    <p class="middle_text">
        - предоставившим данные, указанные в профиле другого Продавца;
    </p>
    <p class="middle_text">
        - предоставившим некорректную/неполную информацию, предусмотренную Офертой;
    </p>
    <p class="middle_text">
        - с которым ранее Договор был расторгнут по инициативе Клик Маркет;
    </p>
    <p class="middle_text">
        - в иных случаях. <br>
        Клик Маркет вправе отказать такому лицу в регистрации.
    </p>

    <p class="middle_text">
        <span class="title_normal_text">3.8</span> После регистрации на Сервисе Продавец обязан подготовить и
        самостоятельно загрузить через Личный кабинет на Сайте Материалы, содержащие:
    </p>
    <p class="middle_text">
        - фото Товара (должно быть четким и отображать общий вид Товара, а также не содержать водяных знаков. Клик
        Маркет рекомендует использовать разрешение 1200×1200 пикселей);
    </p>

    <p class="middle_text">
        - название Товара;
    </p>
    <p class="middle_text">
        - цену Товара;
    </p>
    <p class="middle_text">
        - информацию о цене и условиях Доставки Товара;
    </p>
    <p class="middle_text">
        - иные сведения о Товаре, предусмотренные действующим законодательством Российской Федерации, или указанные Клик
        Маркет в качестве обязательных.
    </p>


    <p class="middle_text">
        <span class="title_normal_text">3.9</span> Материалы становятся доступными на Сервисе в течение 1 (Одного)
        рабочего дня после их загрузки Продавцом. Клик Маркет в любое время вправе отправить Материалы на доработку
        Продавцу, убрав их с Сервиса, в т. ч. в случае:
    </p>
    <p class="middle_text">
        - предоставления некачественных фото;
    </p>

    <p class="middle_text">
        - недостаточного описания товара;
    </p>
    <p class="middle_text">
        - указания в описании ссылок на сторонние ресурсы ((в том числе любые контактные данные, ссылки на социальные
        сети и т.д.);
    </p>
    <p class="middle_text">
        - создания нескольких Товаров с одинаковыми фото;
    </p>
    <p class="middle_text">
        - установления цен на Товары в размере, превышающем цены, указанные Продавцом на собственных онлайн-ресурсах
        (сайт, социальные сети), а также на других онлайн-ресурсах (агрегаторы информации о товарах/услугах, сервисы
        размещения объявлений и т.п.);
    </p>
    <p class="middle_text">
        - присутствия в Материалах запрещенных законодательством Российской Федерации Товаров, перечень которых
        предусмотрен п. 3.11. Оферты;
    </p>
    <p class="middle_text">
        - несоответствия Материалов действующему законодательству Российской Федерации;
    </p>
    <p class="middle_text">
        - непредоставления Продавцом по запросу Клик Маркет документов, подтверждающих правомерность размещения
        Материалов, документов, подтверждающих соответствие Товаров требованиям Оферты и законодательства Российской
        Федерации.
    </p>


    <p class="middle_text">
        <span class="title_normal_text">3.10</span> Места размещения Материалов на Сервисе определяются Клик Маркет.
        Места размещения Материалов на Сервисе могут быть пересмотрены в одностороннем порядке;
    </p>

    <p class="middle_text">
        <span class="title_normal_text">3.11</span> До загрузки Материалов Клик Маркет рекомендует Продавцу ознакомиться
        с перечнем запрещенных к размещению на Сервисе законодательством Российской Федерации Товаров:
    </p>
    <p class="middle_text">
        - алкогольная продукция;
    </p>

    <p class="middle_text">
        - растения, животные и птицы, добыча и реализация которых запрещена действующим законодательством, редкие и
        исчезающие виды животного и растительного мира, занесенные в Красную книгу России, а также подпадающие под
        действие Конвенции о международной торговле видами дикой флоры и фауны, находящимися под угрозой исчезновения,
        их части или дериваты;
    </p>
    <p class="middle_text">
        - драгоценные и редкоземельные металлы и изделия из них (исключая ювелирные изделия);
    </p>
    <p class="middle_text">
        - вооружение, боеприпасы к нему, взрывчатые вещества, патроны, средства взрывания, пороха;
    </p>
    <p class="middle_text">
        - рентгеновское оборудование, приборы и оборудование с использованием радиоактивных веществ и изотопов,
        радиоактивные вещества;
    </p>
    <p class="middle_text">
        - продукция порнографического характера;
    </p>
    <p class="middle_text">
        - шифровальная техника;
    </p>
    <p class="middle_text">
        - яды, наркотические средства и психотропные вещества, их прекурсоры;
    </p>
    <p class="middle_text">
        - биоматериалы, инъекционные растворы, иммунобиологические препараты;
    </p>
    <p class="middle_text">
        - продукция, произведенная из токсичных материалов;
    </p>
    <p class="middle_text">
        - спирт этиловый;
    </p>
    <p class="middle_text">
        - специальные и иные технические средства, предназначенные для негласного получения информации;
    </p>
    <p class="middle_text">
        - все виды оружия — в том числе травматические средства;
    </p>
    <p class="middle_text">
        - музейные предметы и музейные коллекции, включенные в состав Музейного фонда Российской Федерации;
    </p>
    <p class="middle_text">
        - продукция, полученная в результате браконьерской деятельности;
    </p>

    <p class="middle_text">
        - табачная продукция;
    </p>

    <p class="middle_text">
        - рецептурные лекарственные средства;
    </p>

    <p class="middle_text">
        - кальяны и товары, в которых есть никотин;
    </p>

    <p class="middle_text">
        - адаптеры ремней безопасности;
    </p>

    <p class="middle_text">
        - бескаркасные детские кресла;
    </p>

    <p class="middle_text">
        - дорожные знаки;
    </p>


    <strong class="sub_title_text">4. Порядок расчетов и стоимость услуг</strong>

    <p class="middle_text">
        <span class="title_normal_text">4.1.</span> Порядок расчета стоимости Услуг:
    </p>
    <p class="middle_text">
        <span class="title_normal_text">4.1.1.</span> Стоимость услуг, предусмотренных п.2.2.1. – 2.2.2. Оферты,
        рассчитывается в соответствии с приложениями к п.4.4.1. Оферты;
    </p>
    <p class="middle_text">
        <span class="title_normal_text">4.1.2.</span> Стоимость Услуг (агентское вознаграждение), предусмотренных
        п.2.2.3. Оферты, рассчитывается от стоимости услуг по Договорам на использование Карточек товаров, без учета
        скидок и стоимости Доставки Товара;
    </p>
    <p class="middle_text">
        <span class="title_normal_text">4.1.3.</span> Стоимость услуг, предусмотренных п.2.2.4. Оферты, рассчитывается
        от стоимости Товара, указанного в Заказе, исполненном с использованием соответствующей Карточки товара, без
        учета скидок и стоимости Доставки Товара;
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.2.</span> Продавец самостоятельно устанавливает стоимость Услуг,
        предусмотренных в п.2.2.1. – 2.2.2. Оферты, исходя из минимальных размеров, указанных в приложениях к п. 4.4.1.
        Оферты.
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.3.</span> Стоимость услуг рассчитывается только от исполненных Заказов.
        Стоимость Услуг включает в себя НДС, если применимо, по ставке, предусмотренной действующим законодательством
        РФ. Агентское вознаграждение включает в себя компенсацию всех расходов, понесенных Клик Маркетом в процессе
        исполнения поручения, если иное не будет установлено соглашением сторон. Стороны признают, что в целях
        определения стоимости Услуг используются исключительно данные статистики Клик Маркета.
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.4.</span> Стоимость услуг, предусмотренных п. 2.2.1.-2.2.2. Оферты,
        рассчитывается из 20% (вознаграждение Сервиса) от стоимости Товаров исполненного Заказа и 3% (эквайринг) от
        полной стоимости исполненного Заказа.
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.5.</span> Клик Маркет не взимает плату за Услуги в случае отказа Пользователя
        от Заказ или отказа Пользователя/Получателя от Товара. Взимает плату за Услуги в случае частичного или полного
        возврата продавцом денежных средств Пользователю на основании поступившей от Пользователя претензии.
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.6.</span> Не вступая в противоречие с иными положениями Оферты, Стороны
        зафиксировали условия агентского поручения Продавца и порядок его исполнения Клик Маркетом. Клик Маркет по
        поручению Продавца, действуя от своего имени, но за счет Продавца, в соответствии с Главой 52 Гражданского
        кодекса Российской Федерации осуществляет:
    </p>

    <p class="middle_text">
        - прием безналичных денежных средств от Пользователей и перечисление их Продавцу; <br>
        - перечисление Пользователям денежных средств, возвращаемых Продавцом;<br>
        - формирование чеков на проведение полного расчета на зачет предоплаты (зачет аванса и/или предыдущих платежей),
        полученной от Пользователей.
    </p>

    <p class="middle_text">
        Прием денежных средств Клик Маркетом осуществляется исключительно в связи с тем, что Клик Маркет посредством
        оказания Продавцу Услуг участвует в реализации Продавцом Пользователю Товаров, в оплату которых принимаются
        денежные средства. При проведении расчетов с физическими лицами Клик Маркет не является платежным агентом в силу
        п.п. 1, 4 ч. 2 ст. 1 Федерального закона от 03.06.2009 № 103-ФЗ «О деятельности по приему платежей физических
        лиц, осуществляемой платежными агентами».<br>
        Срок действия поручения, предусмотренного настоящим пунктом Оферты, равен сроку оказания Услуг Клик Маркетом по
        Договору. Вознаграждение за исполнение поручения включено в стоимость Услуг, определяемую в соответствии с
        Офертой.
    </p>

    <p class="middle_text">
        <span class="title_normal_text"> 4.7.</span> Оплата Услуг (в том числе агентского вознаграждения) производится
        путем удержания соответствующей суммы из денежных средств, полученных Клик Маркетом от Пользователей и
        подлежащих перечислению Продавцу. В случае, если полученных Клик Маркетом денежных средств недостаточно для
        оплаты Услуг и/или уплаты неустоек и/или любых других платежей, Продавец обязан оплатить недостающие денежные
        средства в течение 5 (Пяти) рабочих дней с момента получения соответствующего требования от Клик Маркет. При
        этом Клик Маркет вправе в одностороннем порядке осуществлять зачет любых встречных денежных требований.
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.8.</span> Информация о сумме денежных средств, подлежащих перечислению
        Продавцу, отображается в Личном кабинет Продавца на Сайте. Реестр на оплату формируется Клик Маркетом каждый
        вторник. Перечисление денежных средств Продавцу производится Клик Маркетом не позднее 10 (Десяти) дней с момента
        формирования реестра на оплату.
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.9.</span> Отчетным периодом является календарный месяц. В течении 7 )Семи)
        рабочих дней с момента окончания отчетного периода Клик Маркет готовит и передаёт Продавцу следующие документы:
    </p>

    <p class="middle_text">
        - односторонний Акт об оказанных услугах; <br>
        - односторонний Отчет об исполнении поручения.
    </p>


    <p class="middle_text">
        <span class="title_normal_text"> 4.10.</span> В течение 5 (Пяти) рабочих дней с момента получения Отчетных
        документов (электронных образов или оригиналов – в зависимости от того, что произойдет ранее) Продавец вправе
        направить Клик Маркету мотивированные возражения в отношении данных, указанных в Отчетных документах. Если в
        установленный срок от Продавца не поступят мотивированные возражения, Услуги считаются оказанными Клик Маркетом
        надлежащим образом (в объеме и сроки согласованные сторонами), принятыми Продавцом в полном объеме и подлежащими
        оплате, при этом Продавец утрачивает право на предъявление каких-либо возражений в будущем.
    </p>


    <strong class="sub_title_text">5. Права и обязанности сторон </strong>

    <p class="middle_text">
        <span class="title_normal_text">5.1.</span> Клик Маркет обязуется:<br>
        - оказывать Продавцу Услуги, осуществлять исполнение иных обязательств, предусмотренных Офертой;<br>
        - консультировать Продавца по вопросам работы Клик Маркет;<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text">5.2.</span> Клик Маркет вправе:<br>
        - временно приостановить оказание Услуг по техническим, технологическим или иным причинам, препятствующим
        оказанию Услуг и исполнению иных обязательств на время устранения таких причин;<br>
        - осуществлять любые действия (контрольные мероприятия) по проверке соответствия содержания представленных
        Материалов требованиям Оферты, а также проверку надлежащего и добросовестного выполнения Продавцом своих
        обязательств, в т.ч. получать (запрашивать) у Продавцов информацию;<br>
        - при выявлении Недействительных заказов блокировать работу Продавца на Сервисе, а также в порядке ст. 406.1 ГК
        РФ получить возмещение имущественных потерь Клик Маркета за счет Продавца;<br>
        - приостановить оказание Услуг/блокировать работу Продавца на Сервисе при выявлении неправомерного размещения
        Товаров, сведения о которых содержатся в представленных Продавцом Материалах или незаконного способа их введения
        в гражданский оборот на территории Российской Федерации;<br>
        - приостановить оказание Услуг / блокировать работу Продавца на Сервисе, а также отказать в размещении
        Материалов, в случае если, по мнению Клик Маркета, размещение материалов противоречит требованиям действующего
        законодательства, либо повлекло или может повлечь предъявление претензий в отношении нарушения прав третьих лиц;<br>
        - вносить изменения в Оферту и иные документы, указанные в п. 3.2. Оферты. В случае внесения Клик Маркет
        изменений в Оферту и/или в документы, указанные в п. 3.2. Оферты, такие изменения вступают в силу с момента
        размещения измененного текста Оферты и/или документов, указанных в п. 3.2. Оферты, в сети Интернет по адресам,
        указанным в п. 1.1. и 3.2. Оферты, если иной срок вступления изменений в силу не определен дополнительно при
        таком размещении. Клик Маркет вправе уведомить Продавца об изменениях Оферты любым из следующих способов: в
        Личном кабинете и/или по адресу электронной почты, указанному при регистрации на Сервисе. Продавец соглашается и
        признает, что внесение изменений в Оферту и/или в документы, указанные в п. 3.2. Оферты, влечет за собой
        внесение этих изменений в Договор и эти изменения в Договор вступают в силу одновременно с изменениями в Оферту
        и/или в документы, указанные в п. 3.2. Оферты. В случае если Клик Маркетом были внесены изменения, с которыми
        Продавец не согласен, Продавец обязан прекратить использование Сервиса;<br>
        - публично ссылаться на фирменное наименование Продавца (а также его логотип или товарные знаки) для указания
        его в качестве одного из Продавцов без выплаты Продавцу каких-либо денежных сумм;<br>
        - за свой счет проводить маркетинговые и иные мероприятия, направленные на продвижение Сервиса;<br>
        - публично ссылаться на фирменное наименование Правообладателя (а также его логотип или товарные знаки) для
        указания его в качестве правообладателя Карточек товаров, без выплаты Правообладателю каких-либо денежных
        сумм;<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text">5.3.</span> Продавец обязан:<br>
        - использовать все медиафайлы, авторское право которых он сможет доказать в случае необходимости;<br>
        - в течение 15 (Пятнадцати пяти) минут с момента получения Заказа принять или отклонить Заказ, при этом если
        Заказ получен в получен в рабочее время Продавца, течении срока начинается с момента получения Заказа. Течение
        срока прерывается и в оставшейся части переносится на следующий рабочий день Продавца, если на момент получения
        Заказа до конца рабочего дня Продавца осталось менее 15 (Пятнадцати) минут. Если Заказа получен во внерабочее
        время Продавца, течение срока начинается с начала первого рабочего дня Продавца, следующего за днем получения
        Заказа;<br>
        - хранить копии документов и иной информации, связанной с реализованными Товарами, а также выполненными
        Заказами, в течение 1 (Одного) года с момента реализации/выполнения и при запросе Клик Маркета предъявлять
        документы/информацию не позднее 1 (Одного) рабочего дня с даты получения соответствующего запроса;<br>
        - выполнять Заказы лично, за исключением случаев, предусмотренных Офертой;<br>
        - ознакомиться и соблюдать положения п. 3.3. Условий использования Сервиса;<br>
        - предоставить Клик Маркету Материалы и информацию в соответствии с требованиями Оферты;<br>
        - упаковать Товар соответственно условиям транспортировки. Упаковка должна обеспечить полную защиту Товара от
        повреждений и потерь в процессе транспортировки, а окружающую среду от загрязнений;<br>
        - при подготовке, создании, изменении Материалов соблюдать все требования Клик Маркета к Материалам и условиям
        размещения, обусловленным Офертой, а также все применимые нормы и требования действующего законодательства
        Российской Федерации, в том числе Федерального закона от 13.03.2006 № 38-ФЗ «О рекламе», законодательства об
        интеллектуальной собственности, Федерального закона от 26.07.2006 № 135-ФЗ «О защите конкуренции», Закона РФ от
        07.02.1992 № 2300-1 «О защите прав потребителей», но не ограничиваясь перечисленным;<br>
        - предоставлять Материалы, содержание которых соответствует текущим предложениям Продавца;<br>
        - устанавливать цены на Товары на Сервисе в размере, не превышающем цены, указанные Продавцом на собственных
        онлайн-ресурсах (сайт, социальные сети), а также на других онлайн-ресурсах (агрегаторы информации о
        товарах/услугах, сервисы размещения объявлений и т.п.);<br>
        - не использовать результаты интеллектуальной деятельности третьих лиц в составе Материалов без надлежащих
        правовых оснований;<br>
        - не оспаривать тот факт, что показ предоставленных им Материалов может сопровождаться дополнительными
        информационными материалами Клик Маркет;<br>
        - без предварительного письменного согласования с Клик Маркет не распространять вместе с Товаром какие-либо
        рекламные материалы (как собственные, так и третьих лиц);<br>
        - не распространять, не публиковать или иным способом не предоставлять некорректную информацию о Сервисе; <br>
        - не использовать бренд трафик Клик Маркет для рекламы своего магазина в контекстной рекламе;<br>
        - обеспечивать безопасность персональных данных Пользователей/представителей Пользователей;<br>
        - в случае изменения информации, указанной в п. 3.3 Оферты, в течение 1 (Одного) рабочего дня с момента внесения
        таких изменений сообщить об этом Клик Маркету;<br>
        - оперативно (т. е. в течение 30 (Тридцати) минут с момента получения запроса, если запрос поступил в рабочее
        время Продавца, или в течение 30 (Тридцати) минут с момента начала первого рабочего дня, следующего за днем
        получения запроса, если запрос получен в нерабочее время Продавца) отвечать на запросы Пользователей и Службы
        поддержки Сервиса;<br>
        - обрабатывать персональные данные Пользователей/Получателей в соответствии с требованиями Федерального закона
        от 27.07.2006 № 152-ФЗ «О персональных данных», без возможности передачи данных Пользователя/Получателя третьим
        лицам за исключением случаев, прямо установленных законодательством РФ;<br>
        - соблюдать условия продажи Товаров, имеющих возрастные ограничения, установленные законодательством РФ;<br>
        - в случае выполнения Заказов, содержащих Товары, имеющие возрастные ограничения, в момент передачи Товара
        Пользователю/Получателю обеспечить проверку возраста Пользователя/Получателя на основании паспорта или иного
        документа, удостоверяющего личность. Продавец самостоятельно несет ответственность за проверку возраста
        Пользователя/Получателя в соответствии с требованиями законодательства РФ.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">5.4.</span> Продавец вправе:<br>
        - пользоваться услугами Службы поддержки Сервиса, подавать обращения по вопросам работы Сервиса.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">5.5.</span> Каждая Сторона сохраняет все права на товарные знаки и/или иные
        принадлежащие ей объекты интеллектуальной собственности (в том числе логотипы, фотографии, видеоматериалы,
        дизайны, чертежи), которые могут быть использованы в рамках Договора. Заключение Договора не может толковаться
        как предоставление Продавцу каких-либо лицензий и/или права пользования в отношении объектов интеллектуальной
        собственности Клик Маркет.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">5.6.</span> Размещая Материалы на Сервисе Продавец одновременно предоставляет
        Клик Маркету передаваемую простую (неисключительную) лицензию на право использовать соответствующие Материалы в
        деятельности Клик Маркета и/или иных Продавцов, в том числе для целей рекламирования Товара и/или Сервиса, и/или
        Продавца, и/или иных Продавцов на территории всего мира без выплаты какого-либо вознаграждения или необходимости
        получения каких-либо дополнительных согласий и/или разрешений. Лицензия предоставляется на весь срок действия
        исключительных прав на Материалы, в следующем объеме:<br>
        - воспроизведение Материалов;<br>
        - распространение Материалов любым способом;<br>
        - публичный показ Материалов, включая публичное исполнение произведений, в состав которых входят Материалы;
        доведение Материалов до всеобщего сведения;<br>
        - сообщение в эфир и по кабелю;<br>
        - переработку Материалов;<br>
        - включение Материалов в состав составного произведения или иного сложного объекта.
        Продавец гарантирует и подтверждает, что Продавец является законным правообладателем Материалов или
        предоставляет право использования Материалов на иных законных основаниях, а также что предоставление права
        использования Материалов и использование Материалов не нарушают права третьих лиц и не будут нарушать их в
        течение всего срока использования.<br>
    </p>

    <strong class="sub_title_text">6. Ответственность </strong>
    <p class="middle_text">
        <span class="title_normal_text">6.1.</span>
        Ответственность за исполнение Договора/Договоров, заключенного/заключенных между Пользователем и Продавцом с
        использованием Сервиса, а также за соблюдение иных прав Пользователей несет Продавец, если иное прямо не
        предусмотрено действующим законодательством Российской Федерации. В отношениях с физическими лицами
        ответственность Клик Маркета ограничена ответственностью, предусмотренной Законом РФ от 07.02.1992 № 2300-1 «О
        защите прав потребителей» для владельцев агрегаторов.
    </p>

    <p class="middle_text">
        <span class="title_normal_text">6.2.</span>
        Клик Маркет предоставляет Продавцам функцию информационного сопровождения рассмотрения претензий Пользователей.
        Указанное не подразумевает наличие у Клик Маркета полномочий на удовлетворение претензий Пользователей, в том
        числе связанных с ненадлежащим качеством Товара и/или услуг по Доставке Товара. При предоставлении такой функции
        Клик Маркет выступает в качестве арбитра.
    </p>


    <p class="middle_text">
        <span class="title_normal_text">6.3.</span>
        В течение 3 (Трех) дней с момента выполнения Продавцом Заказа Пользователь имеет возможность урегулировать любую
        претензию с Продавцом через Сервис. По истечении указанного срока Пользователь урегулирует все претензии
        напрямую с Продавцом без привлечения Клик Маркет, если иное не предусмотрено действующим законодательством
        Российской Федерации.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">6.4.</span>
        Выступая в качестве арбитра, Клик Маркет вправе запросить у Продавца сведения и документы, необходимые для
        принятия решения по претензии. Сведения и документы должны быть предоставлены в течение 1 (одного) рабочего дня
        Продавца с момента получения соответствующего требования. Непредставление Продавцом запрошенных сведений и/или
        документов может стать основанием для вынесения решения по претензии в пользу Пользователя.
    </p>

    <p class="middle_text">
        <span class="title_normal_text">6.5. В случае:</span><br>
        - отказа Продавца от выполнения Заказа по основаниям, не связанным с действиями Пользователя/Получателя (в т.ч.
        в связи с отсутствием Товара, а также в случае, когда отказ от Заказа поступил от Пользователя по просьбе
        Продавца), Продавец по решению Клик Маркета получает блокировку работу Продавца на срок:
        <br>
        - Заказ находится в статусе «В ожидании» - срок блокировки магазина Продавца 1 (Один) календарный день. При этом
        Сервис вправе аннулировать такой Заказ без предварительного согласования с Продавцом;<br>
        - Заказ находится в статусе «Подтверждён» - срок блокировки магазина Продавца 2 (Два) календарных дня. Оферты за
        каждый отказ;<br>
        - если Заказ не был принят/отклонен Продавцом в срок, указанный в п.5.3. Оферты (автоотмена), срок блокировки
        магазина Продавца 2 (Два) календарных дня. Оферты за каждый отказ;<br>
        Если в текущем отчетном периоде Продавец отказался только от 1 (Одного) Заказа, Клик Маркет вправе не применять
        штрафные санкции к Продавцу.<br>
        Если в текущем отчетном периоде Заказ не был принят/отклонен Продавцом в срок, указанный в п. 5.3. Оферты, 4
        (Четыре) раза и более, Клик Маркет вправе заблокировать работу Продавца на Сервисе.
    </p>

    <p class="middle_text">
        <span class="title_normal_text">6.6. </span>
        Продавец несет ответственность в полном объеме за соблюдение всех требований законодательства, в том числе
        законодательства об интеллектуальной собственности, о конкуренции, о персональных данных, но не ограничиваясь
        перечисленным, в отношении содержания и формы Материалов, за соответствие Товаров требованиям законодательства
        Российской Федерации, за то, что Товары не являются фальсифицированными и/или контрафактными.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">6.7. </span>
        Продавец несет ответственно за использование медиафайлов, на которые он не имеет авторских прав.
    </p>

    <strong class="sub_title_text">7. Гарантии </strong>
    <p class="middle_text">
        <span class="title_normal_text">7.1.</span>
        В течение срока действия Договора Клик Маркет предпримет все усилия для устранения каких-либо сбоев и ошибок в
        случае их возникновения. При этом Клик Маркет не гарантирует отсутствия ошибок и сбоев при размещении
        Материалов, а также при оказании услуг, предусмотренных п. 2.2 Оферты, в том числе в отношении работы
        программного обеспечения.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">7.2.</span>
        За исключением гарантий, прямо указанных в тексте Оферты, Клик Маркет не предоставляет никаких иных прямых или
        подразумеваемых гарантий по Договору и прямо отказывается от каких-либо гарантий или условий в отношении не
        нарушения прав и соответствия Услуг конкретным целям Продавца.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">7.3.</span>
        Продавец гарантирует и подтверждает, что:<br>
        - является должным образом зарегистрированным и законно действующим юридическим лицом/индивидуальным
        предпринимателем/налогоплательщиком;<br>
        - Продавец указал достоверные данные, в том числе персональные данные своих представителей при регистрации,
        Продавцом получены согласия своих представителей на предоставление их персональных данных Клик Маркет;<br>
        - Продавец: а) полностью ознакомился с условиями Оферты; б) полностью понимает предмет Оферты; в) полностью
        понимает значение и последствия своих действий в отношении заключения и исполнения Договора;<br>
        - размещенные Материалы не нарушают и не влекут за собой нарушение каких-либо прав третьих лиц и действующего
        законодательства Российской Федерации;<br>
        - Товары, сведения о которых содержатся в представленных Продавцом Материалах, законным способом были введены в
        гражданский оборот на территории Российской Федерации, не являются фальсифицированными и/или контрафактными,
        соответствуют законодательству Российской Федерации, в том числе законодательству о техническом регулировании,
        содержат все необходимые сведения, предусмотренные законодательством о защите прав потребителей;<br>
        - Товары, реализуемые Продавцом посредством Сервиса, соответствуют требованиям Постановления Правительства РФ от
        26 апреля 2019 г. № 515 “О системе маркировки товаров средствами идентификации и прослеживаемости движения
        товаров”;<br>
        - Продавец является добросовестным налогоплательщиком, состоит на налоговом учете и соблюдает требования
        налогового законодательства Российской Федерации, а также является самостоятельным плательщиком налогов и сборов
        в соответствии с законодательством Российской Федерации;<br>
        - Продавец соблюдает законодательство Российской Федерации, регулирующее порядок привлечения иностранной рабочей
        силы;<br>
        - Продавец имеет все необходимые полномочия, одобрения и разрешения компетентных органов управления Продавца
        (общее собрание акционеров/участников, совет директоров и др.) на заключение Договора; подписание и исполнение
        Договора не являются нарушением Устава Продавца (если применимо).<br>
    </p>

    <strong class="sub_title_text">8. Урегулирование споров </strong>

    <p class="middle_text">
        <span class="title_normal_text">8.1.</span>
        Договор, его заключение и исполнение регулируются действующим законодательством Российской Федерации. Все
        вопросы, неурегулированные Офертой или урегулированные не полностью, регулируются в соответствии с материальным
        правом Российской Федерации.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">8.2.</span>
        Стороны будут стремиться разрешать возникшие споры, разногласия и/или требования путем переговоров.
        Претензионный порядок разрешения споров, разногласий и/или требований обязателен для Сторон. Срок рассмотрения
        претензии составляет 10 (Десять) дней с даты ее получения. Соблюдение требований к направлению юридически
        значимых сообщений, закрепленных в Разделе 9 Оферты, при направлении претензии является необходимым условием
        соблюдения претензионного порядка разрешения споров. Претензия считается доставленной при недобросовестном
        воспрепятствовании или уклонении от получения отправления, письма, а также блокировки получения электронных
        сообщений от направляющей Стороны.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">8.3.</span>
        Споры, разногласия и/или требования, не урегулированные в претензионном порядке, подлежат рассмотрению в суде по
        месту нахождения Клик Маркет в соответствии с действующим законодательством Российской Федерации.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">8.4.</span>
        В случае возникновение споров между Продавцами об использовании его изображений Продавец может в досудебном
        порядке обратиться с Сервису за разрешением ситуации с просьбой удалить контент, предварительно предоставив
        авторские права на изображения. Правообладатель, увидев, что его интересы затронуты, может обратиться в суд,
        взыскав с Продавца, который использовал не свои изображения, компенсацию.
    </p>

    <strong class="sub_title_text">9. Информационный обмен </strong>
    <p class="middle_text">
        <span class="title_normal_text">9.1.</span>
        Если иными положениями Оферты прямо не установлено иное, все заявления, уведомления, извещения, требования или
        иные юридически значимые сообщения, требуемые или направляемые в связи с Договором, могут быть отправлены
        заказным письмом, курьерской службой, электронным сообщением, адресованным Стороне по ее реквизитам или адресу
        электронной почты. Любые юридически значимые сообщения считаются переданными:<br>
        - на день Доставки – при его отправлении почтой или курьерской службой;<br>
        - на день отправления – в случае направления посредством электронной почты;<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text">9.2.</span>
        Юридически значимые сообщения, отправленные средствами электронной почты, обладают полной юридической силой и
        могут быть использованы в качестве доказательств в суде при соблюдении нижеследующих условий:<br>
        - любые юридически значимые сообщения, направленные на изменение условий Договора, полное или частичное
        прекращение Договора, прекращение обязательств любой из Сторон по любым основаниям, за исключением их
        надлежащего исполнения, заявление претензионных требований, передаваемые посредством электронного сообщения,
        должны (1) в обязательном порядке содержать в качестве вложения светокопию исполненного на бумаге надлежащим
        образом оформленного документа, отражающего соответствующее юридически значимое сообщение, подписанного
        полномочным на то лицом, и (2) в случае отправки по электронной почте направляться на адрес электронной почты
        Стороны-получателя, указанный в п. 9.3;<br>
        - распечатка отчета об отправке электронного сообщения и/или скриншота экрана Чата рассматривается Сторонами в
        качестве допустимого письменного доказательства факта отправления юридически значимого сообщения и его получения
        Стороной.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text">9.3.</span>
        Адреса электронной почты Сторон:
        - для Клик Маркет: <a href="mailto:hello@click-market.ru">hello&#64;click-market.ru</a>;
        - для Продавца: адрес электронной почты, указанный при регистрации на Сервисе.
    </p>

    <strong class="sub_title_text">10. Сервис </strong>

    <p class="middle_text">
        <span class="title_normal_text">10.1.</span>
        Продавец несет ответственность за собственные действия в связи с использованием Сервиса.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">10.2.</span>
        Клик Маркет по своему усмотрению вправе в любое время изменять оформление Сервиса, содержание, функциональность,
        изменять или дополнять используемые скрипты, программное обеспечение и т. д.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">10.3.</span>
        Клик Маркет обеспечивает функционирование и работоспособность Сервиса и обязуется оперативно восстанавливать их
        работоспособность в случае технических сбоев и перерывов. Клик Маркет не несет ответственности за временные сбои
        и перерывы в работе Сервиса и вызванные ими потери информации. Клик Маркет не несет ответственности за любой
        ущерб компьютеру/мобильным устройствам Продавца, а также любому другому оборудованию или программному
        обеспечению, вызванный или связанный с использованием Сервиса.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">10.4.</span>
        Клик Маркет не несет ответственности перед Продавцом за невозможность использования Сервиса по каким-либо
        независящим от Клик Маркета причинам.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">10.5.</span>
        Продавец вправе потребовать возмещения убытков, только если будет доказано прямое виновное действие
        (бездействие) Клик Маркета, совершенное с прямым умыслом, направленным на причинение ущерба Продавцу.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">10.6.</span>
        За исключением случаев, указанных в Оферте, Клик Маркет ни при каких обстоятельствах не несет ответственности
        перед Продавцом или перед любыми третьими лицами за любой косвенный, случайный, неумышленный ущерб, включая
        упущенную выгоду или потерянные данные, вред чести, достоинству или деловой репутации, связанные с
        использованием Сервиса.
    </p>

    <strong class="sub_title_text">
        11. Заключение и расторжение договора
    </strong>


    <p class="middle_text">
        <span class="title_normal_text">11.1.</span>
        Договор вступает в силу с момента акцепта Продавцом Оферты и действует до момента расторжения Договора.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">11.2.</span>
        Договор в любое время может быть расторгнут по соглашению Сторон.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">11.3.</span>
        Договор может быть расторгнут Клик Маркетом в случае нарушения Продавцом условий Договора немедленно с
        письменным уведомлением Продавца в следующих случаях:<br>
        - неоднократное нарушение сроков Доставки Товара;<br>
        - неоднократное нарушение требований к качеству Товара;<br>
        - неоднократная замена Товара;<br>
        - использование чужих Материалов;<br>
        - рассылка Пользователям спама;<br>
        - грубое общение с Пользователями и/или Клик Маркетом;<br>
        - игнорирование Заказов;<br>
        - неоднократные отказы от Заказов без обоснованных причин;<br>
        - установление цен на Товары на Сервисе в размере, превышающем цены, указанные Продавцом на собственных
        онлайн-ресурсах (сайт, социальные сети), а также на других онлайн-ресурсах (агрегаторы информации о
        товарах/услугах, сервисы размещения объявлений и т.п.);<br>
        - предоставление низкокачественных Материалов и отказ в устранении замечаний Клик Маркета;<br>
        - предоставление недостоверной информации о Продавце и/или реализуемых Товарах;<br>
        - непредоставление или несвоевременное предоставление документов, подтверждающих право на реализацию Товаров и
        размещение Материалов;<br>
        - сообщение некорректных реквизитов;<br>
        - наличие задолженности перед Клик Маркетом;<br>
        - иные нарушения условий Оферты.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text">11.4.</span>
        Договор может быть немотивированно расторгнут Продавцом или Клик Маркетом в одностороннем внесудебном порядке
        путем направления письменного уведомления другой стороне не позднее, чем за 7 (Семь) дней до даты прекращения
        Договора. В этом случае ни одна из сторон не вправе требовать никаких убытков от другой стороны.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">11.5.</span>
        Обязательства Сторон по Договору, которые в силу своей природы должны продолжать действовать (включая
        обязательства в отношении конфиденциальности, проведения взаиморасчетов, использования информации, но не
        ограничиваясь указанным), остаются в силе после окончания действия Договора.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">11.6.</span>
        Прекращение действия Договора по любому основанию не освобождает Стороны от ответственности за нарушения условий
        Договора, возникшие в течение срока его действия.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">11.7.</span>
        В силу технических особенностей работы Сервиса данные о Продавце, а также о выполненных им заказах после
        расторжения Договора с Сервиса не удаляются. Удалению подлежат только персональные данные сотрудников Продавца и
        иных лиц, чьи персональные данные были предоставлены Продавцом.
    </p>

    <strong class="sub_title_text">12. Конфиденциальность </strong>
    <p class="middle_text">
        <span class="title_normal_text">12.1.</span>
        Для целей Оферты/Договора в дальнейшем под «Раскрывающей стороной» будет пониматься Клик Маркет, а под
        «Принимающей стороной» будет пониматься Продавец.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.2.</span>
        Конфиденциальная информация – сведения любого характера, относящиеся в соответствии с законодательством
        Российской Федерации к персональным данным, банковской тайне, либо сведения, в отношении которых Раскрывающей
        стороной в соответствии с действующим законодательством Российской Федерации установлен режим коммерческой
        тайны, а также любые другие сведения, в отношении которых Раскрывающей стороной заявлено требование об их
        конфиденциальности, которые принадлежат Раскрывающей стороне, или на которые Раскрывающая сторона получила
        определенные полномочия по их использованию.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.3.</span>
        В частности, к конфиденциальной информации относится:<br>
        - информация о результатах интеллектуальной деятельности, включая подготовительные документы и их проекты, и/или
        о принципах и алгоритмах их работы, используемых технологиях, реализованных функциях, устройстве интерфейса,
        маркетинговых инструментах, в т.ч. инструментах (механиках) продвижения, системе и методах представления,
        организации и систематизации информации в базах данных, имеющихся ошибках в работе программного обеспечения,
        переданные Раскрывающей стороной, либо ставшие известными Принимающей стороне в связи с исполнением
        Договора;<br>
        - сведения о содержании деловых переговоров, обсуждений, или консультаций Сторон Договора;<br>
        - любая информация, оформленная в письменной или иной материальной форме, если такая информация явно обозначена
        как «Коммерческая тайна», «Конфиденциальная информация» либо аналогичным образом.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.4.</span>
        Информация, предоставленная в устной форме, будет считаться конфиденциальной информацией только в том случае,
        если в момент передачи будет идентифицирована как конфиденциальная информация, информация, составляющая
        коммерческую тайну, информация для служебного пользования или аналогичным образом, и при условии предоставления
        Раскрывающей стороной Принимающей стороне письменного подтверждения об установлении режима конфиденциальности в
        отношении указанной информации в течение 10 (Десяти) дней после ее передачи.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.6.</span>
        Принимающая сторона обязуется исполнять следующие обязательства по обеспечению конфиденциальности
        информации:<br>
        - в течение 3 (Трех) лет со дня получения конфиденциальной информации держать такую информацию в тайне, соблюдая
        интересы Раскрывающей стороны, не использовать конфиденциальную информацию, за исключением тех случаев, когда
        такое использование осуществляется в интересах Раскрывающей стороны (по согласованию с ней) и /или в целях
        исполнения обязательств по Договору, а также обязуется не создавать копий конфиденциальной информации без
        разрешения Раскрывающей стороны. Принимающая сторона ограничивает разглашение конфиденциальной информации,
        предоставляя доступ к ней только тем сотрудникам Принимающей стороны (включая сотрудников – физических лиц,
        работающих на Принимающую сторону по гражданско-правовым договорам, работников субподрядчиков) или ее
        аффилированных лиц, для деятельности которых необходимо знание такой информации. При этом Принимающая сторона
        обязана заключить с указанными лицами индивидуальное соглашение о неразглашении конфиденциальной информации, с
        объемом охраны конфиденциальности, не ниже, чем установлено Договором.<br>
        - не передавать, не предоставлять и не разглашать какую-либо конфиденциальную информацию Раскрывающей стороны
        любому третьему лицу посредством публикации, либо любым иным способом. Передача конфиденциальной информации
        третьим лицам может быть осуществлена только при наличии письменного согласия Раскрывающей стороны на такую
        передачу (за исключением случаев, установленных Договором) и при условии принятия третьими лицами обязательства
        об обеспечении конфиденциальности полученной информации в объеме, не ниже, чем установлено Договором.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.6.</span>
        Принимающая сторона не несет ответственности за раскрытие, и не несет обязанности соблюдать режим
        конфиденциальности в отношении информации:<br>
        - публично раскрытой Раскрывающей стороной либо до, либо после получения Принимающей стороной такой
        информации;<br>
        - независимо разработанной Принимающей стороной, что может быть документально подтверждено Принимающей стороной;<br>
        - которая должна быть раскрыта в соответствии с требованиями законодательства государственным органам и органам
        местного самоуправления, при условии, что Принимающая сторона прилагает все необходимые усилия для надлежащего
        уведомления Раскрывающей стороны о требуемом раскрытии;<br>
        - раскрывается с предварительного письменного согласия Раскрывающей стороны.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.7.</span>
        Принимающая сторона несет ответственность за обеспечение того, чтобы третьи лица, которым была передана
        конфиденциальная информация, сохраняли указанную информацию, не раскрывали ее или не передавали ее какому бы то
        ни было неуполномоченному на ее получение лицу.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.8.</span>
        Право собственности и/или исключительное право на конфиденциальную информацию, полученную Принимающей стороной в
        какой-либо форме, принадлежит Раскрывающей стороне.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.9.</span>
        Принимающая сторона получает права на использование конфиденциальной информации Раскрывающей стороны только в
        целях исполнения обязательств по Договору.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.10.</span>
        За нарушение настоящего Раздела Дополнительного соглашения Принимающая сторона несет ответственность в размере
        документально подтвержденных убытков.
    </p>
    <p class="middle_text">
        <span class="title_normal_text">12.11.</span>
        В дополнение к ответственности, предусмотренной п. 12.10. Оферты, в случае нарушения Принимающей стороной
        положений настоящего раздела Раскрывающая сторона вправе потребовать у Принимающей стороны, а Принимающая
        сторона обязана выплатить штраф в размере 1 000 000 (Один миллион) рублей за каждый подтвержденный случай
        нарушения. Убытки, предусмотренные 12.10. Оферты, взыскиваются сверх штрафа. Сумма штрафа может быть удержана из
        любых денежных средств, причитающихся Продавцу. Обязанность выплаты штрафа не зависит от того, были причинены
        Клик Маркету убытки или нет.
    </p>

    <strong class="sub_title_text">13. Реквизиты Клик Маркет </strong>
    <p class="middle_text">
        ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ДРАЙВ" ИНН 1659056744; ОГРН: 1051637013823; адрес: 420059, Россия,
        Республика Татарстан, г Казань, ул. Павлюхина, 89
    </p>
</div>

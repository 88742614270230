import { Component } from '@angular/core';
//services
import {NavigationService} from "@services/navigation/navigation.service";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {

  constructor(private navigationService: NavigationService ) { }

  navigateBack() {this.navigationService.navigateBack()}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceToComma'
})
export class ReplaceToCommaPipe implements PipeTransform {
  transform(value: string | null): string | null {
    const number = value?.toString();
    return number ? number.replace('.', ',') : null;
  }
}

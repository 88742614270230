import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.scss']
})
export class AppInfoComponent {
  downloadLinksConfig = [
    {
      link: "https://apps.apple.com/am/app/click-seller/id6476865805",
      image: "assets/images/icons/download_app/app-sore-black.svg"
    },
    {
      link: "https://play.google.com/store/apps/details?id=com.tech_yan.click_seller",
      image: "assets/images/icons/download_app/google-play-black.svg"
    },
    {
      link: "https://play.google.com/store/apps/details?id=com.tech_yan.click_seller",
      image: "assets/images/icons/download_app/huawei-black.svg"
    }
  ]

  constructor(private router: Router) {
    const userAgent = navigator.userAgent || navigator.vendor;
    const windowElement: any = window;

    if (/android/i.test(userAgent)) {
      window.location.href = this.downloadLinksConfig[1].link;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !windowElement.MSStream) {
      window.location.href = this.downloadLinksConfig[0].link;
    } else if (/huawei/i.test(userAgent)) {
      window.location.href = this.downloadLinksConfig[2].link;
    }

    this.router.navigate(['/'])
  }

  backToCatalog(): void {
    this.router.navigate(['/'])
  }

  downloadApplicationLink(link: string): void {
    window.open(link, '_blank')
  }
}

<div *ngIf="inputData && ngControl" class="app_input outer">
    <span class="label" [hidden]="!ngControl.control.value">{{(inputData.label || '') | translate}}</span>
    <input *ngIf="inputData.type !== 'textarea'" #inputElement
           [ngClass]="{big_height_input : ngControl.control.value}"
           [class]="inputData.inputClass? inputData.inputClass : ''"
           [type]="inputData.type"
           [readOnly]="inputData.readOnly"
           [attr.disabled]="inputData.disabled || null"
           [placeholder]="(inputData.placeholder || '') | translate"
           [prefix]="inputData.prefix || ''"
           [mask]="inputData.mask || ''"
           [formControl]="ngControl.control"
    >
        <textarea *ngIf="inputData.type == 'textarea'"
                  [ngClass]="{big_height_textarea : ngControl.control.value}"
                  [readOnly]="inputData.readOnly"
                  [attr.disabled]="inputData.disabled || null"
                  [placeholder]="(inputData.placeholder || '') | translate"
                  [prefix]="inputData.prefix || ''"
                  [mask]="inputData.mask || ''"
                  [formControl]="ngControl.control"></textarea>

    <app-error-message [message]="errorBody?.message"></app-error-message>
</div>


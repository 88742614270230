import { Pipe, PipeTransform } from '@angular/core';
import {ParseDateService} from "@services/date/parse-date.service";

@Pipe({
  name: 'showByFormat'
})
export class ShowByFormatPipe implements PipeTransform {

  constructor(
      private dateParseService: ParseDateService
  ) {}

  transform(
      date: string | Date,
      format: string = '',
  ): string {
    return this.dateParseService.changeFormat(date, format);
  }

}

<div class="star_container">
    <div class="title middle_text bold">
        {{title | translate}}
    </div>
    <div class="star_items">
        <div *ngFor="let star of [1,2,3,4,5]; let i = index">
            <img
                 [src]="'assets/images/icon/rating/'+(selectedIndex && selectedIndex >= i + 1 ? 'active_star':'star') + '.svg'"
            >
        </div>
    </div>
</div>

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-clear-value-button',
  template: `<img class="pointer" (click)="reset.emit()" src="assets/images/icon/ic_close.svg">`,
  styles: [`
      img {
        display: block;
        width: 15px;
        height: 15px;
        margin-left: 5px;
      }
      .range_date {
        right: -57px;
        top: 5px;
      }
  `]
})
export class ClearValueComponent {
  @Input() value!: string;
  @Output() reset = new EventEmitter<any>();
}

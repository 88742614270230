import {Component, EventEmitter, Output} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss']
})
export class DialogDeleteComponent {
  @Output() closeModal = new EventEmitter<any>();
  @Output() loading = new EventEmitter<any>();

  constructor(private dialogRef: MatDialogRef<any>) { }

  save() {
    this.dialogRef.close(1);
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    QuickFilterItemInterface, TableConfigs,
    TableData
} from "@intrerfaces/user/components/table-component.interface";

//Services
import {CurrentStateInLocalstorageService} from "@services/state-management/state-in-local-storage.service";

@Component({
    selector: 'app-quick-filter',
    templateUrl: './quick-filter.component.html',
    styleUrls: ['./quick-filter.component.scss']
})
export class QuickFilterComponent implements OnInit {
    @Input() config: TableConfigs | null = null;
    @Input() data: TableData | null = null;
    @Output() makeFilterEvent = new EventEmitter<void>();

    activeItem: any;

    constructor(private stateManagementService: CurrentStateInLocalstorageService) {
    }

    ngOnInit(): void {
        const tableEnum = this.config?.tableEnum;
        if (tableEnum) {
            const currentState = this.stateManagementService.getStateFromLocalStorageForTable(tableEnum);
            if (currentState?.quickFilter) {
                this.activeItem = {...currentState.quickFilter}
            }
        }
    }

    makeFiltration(item: QuickFilterItemInterface): void {
        if (JSON.stringify(this.activeItem) === JSON.stringify(item.data)) {
            return;
        }

        this.activeItem = item.data;
        if (this.data?.pagination) {
            this.data.pagination.quickFilter = item.data;
        }

        this.makeFilterEvent.emit();
    }

}

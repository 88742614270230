import {Component, Input} from '@angular/core';
import {OrderStatusEnum} from "@intrerfaces/user/modules/order.interface";

@Component({
    selector: 'app-status-progress-bar',
    templateUrl: './status-progress-bar.component.html',
    styleUrls: ['./status-progress-bar.component.scss']
})
export class StatusProgressBarComponent {
    @Input() set status(data: OrderStatusEnum) {
        const activeOrderStatus = this.statusesAndActiveStates.find(e => e.status === data)
        if (activeOrderStatus) {
            this.statusesAndActiveStates.forEach((e: any) => {

                if (activeOrderStatus) {
                    if (e.id > activeOrderStatus.id) {
                        e.class = 'disable';
                        if (e.id === activeOrderStatus.id + 1) {
                            e.class = 'default';
                        }
                    }
                    if (e.id <= activeOrderStatus.id) {
                        e.class = 'active';
                        e.passedStatus = true;
                    }

                }
            })
        }
    }

    statusesAndActiveStates = [
        {id: 1, text: 'Ожидает', passedStatus: false, status: OrderStatusEnum.PENDING, class: ''},
        {id: 2, text: 'Принят', passedStatus: false, status: OrderStatusEnum.ACCEPTED, class: ''},
        {id: 3, text: 'Собран', passedStatus: false, status: OrderStatusEnum.COLLECTED, class: ''},
        {id: 4, text: 'В пути', passedStatus: false, status: OrderStatusEnum.ON_THE_WAY, class: ''},
        {id: 5, text: 'Выполнен', passedStatus: false, status: OrderStatusEnum.DELIVERED, class: ''}
    ]

}

import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, finalize, switchMap} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
// Services
import {ShowMessageService} from "../../messages/show-message.service";
import {AuthService} from "../../auth/auth.service";
// Constants
import {CheckToken, TokenInterface} from "@intrerfaces/auth/login.interface";

@Injectable({
    providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {
    isRefreshing = false;

    constructor(
        private showMessageService: ShowMessageService,
        private authService: AuthService
    ) {
    }

    /**
     * Handle All HTTP request add Authorization header (if exist token) and handle errors and show formMessages
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.setAuthorizationToken(req)).pipe(catchError((err) => this.errorHandler(err, req, next)));
    }

    /**
     * Handle errors and detect Error message by response status and check if user not Authorized or token expired
     * do corresponding function Redirect / Refresh Token
     *
     * @param err (Error object form response )
     * @param req (Request object)
     * @param next
     */
    errorHandler(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (err.status >= 400 || err.status === 0) {
            if (err.status === 403) {
                this.showMessageService.showMessageByVariable('permissionError', 'error');
                this.authService.logout();
            } else if (err.status === 401) {
                const url = err.url;
                if (url && (url.indexOf('login') >= 0)) {
                    this.customMessageByTypeLocal(err);
                } else if (!req.url.includes('api/v1/seller/auth')) {
                    return throwError(err);
                } else {
                    this.showMessageService.showMessageByVariable('global', 'error');
                    this.authService.logout();
                }
            } else if (err.status === 404) {
                this.showMessageService.showMessageByVariable('notFound', 'error');
            } else if (err.error?.errors && Object.keys(err.error?.errors)) {
                if (req.headers.get('error') !== 'false') {
                    this.customMessageByTypeLocal(err);
                }
            } else {
                this.showMessageService.showMessageByVariable('global', 'error');
            }
        }

        return throwError(err);
    }


    customMessageByTypeLocal(err: HttpErrorResponse): void {
        const errors = err.error.errors as ({ [key: string]: string });
        const key = Object.keys(errors)[0];
        const value = Object.values(errors)[0];
        this.showMessageService.showCustomMessage((key === 'message' ? '' : (key + ' ')) + value, 'error');
    }

    /**
     * Set Token to request Headers if exist token
     * @param request
     */
    setAuthorizationToken(request: HttpRequest<any>): HttpRequest<any> {
        const token = this.authService.getToken();
        const tokenKey = (request.url.indexOf('renew') > 0) ? 'refreshToken' : 'accessToken';
        if (token) {
            return request.clone({setHeaders: {Authorization: `Bearer ${token[tokenKey]}`, 'X-OsType': 'WEB'}});
        }
        return request.clone({setHeaders: {'X-OsType': 'WEB'}});
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringOrArrayToString'
})
export class StringOrArrayToStringPipe implements PipeTransform {

    transform(value: Array<string> | string,key: any | null = null): string {
        if (typeof value[key] === 'string') {
            return value[key];
        } else {
            const settingsKey = key.split(',')
            return value && value[settingsKey[0]] && value[settingsKey[0]][0] ? value[settingsKey[0]][0][settingsKey[1]] : ''
        }
    }

}
import {Directive, ElementRef, EventEmitter, Host, HostBinding, HostListener, Input, Output} from '@angular/core';
import {TableComponent} from "../../../modules/admin/user/partials/components/table/table.component";
import {animate, AnimationBuilder, AnimationMetadata, style} from "@angular/animations";
import {fromEvent} from "rxjs";

@Directive({
    selector: '[appRightSideDialog]'
})
export class RightSideDialogDirective {
    @HostListener('click') onClick(): void {
        this.tableComponent.openFilterComponent = false
        this.tableComponent.openSettingsComponent = false
    }

    @Input('show')
    set show(show: boolean) {
        const metadata = show ? this.fadeIn() : this.fadeOut();
        const factory = this.builder.build(metadata);
        const player = factory.create(this.el.nativeElement);
        player.play();
    }

    @Output() animationStateEmitter = new EventEmitter<boolean>();
    constructor(private builder: AnimationBuilder, private el: ElementRef, @Host() private tableComponent: TableComponent) {
    }

    private fadeOut(): AnimationMetadata[] {
        this.animationStateEmitter.emit(false);
        return [
            animate('300ms ease-in', style(
                {
                    opacity: 0,
                    visibility: 'hidden'
                })),
        ];
    }

    private fadeIn(): AnimationMetadata[] {
        setTimeout(() => {
            this.animationStateEmitter.emit(true);
        }, 300)
        return [
            animate('300ms ease-in', style({
                opacity: 1,
                visibility: 'visible'
            })),
        ];
    }
}

<div class="table_and_settings_container"
     [ngStyle]="config?.styles?.customStyle || {}"
     [ngClass]="config?.styles?.classes || []"
>
    <div class="text-title p-rl-80 pt-32" *ngIf="config && config.title">
        {{config.title | translate}}
    </div>
    <app-quick-filter *ngIf="config?.actions?.quickFilter"
                      [config]="config"
                      [data]="data"
                      (makeFilterEvent)="makeFastFilter()"
    ></app-quick-filter>

    <div class="search_and_buttons flex_between_align_center mb-10"
         *ngIf="config && config.actions && config.actions.filterBar">
        <ng-container>
            <div class="search_and_buttons--left_side flex_align_center">
                <!-- Search Input -->
                <div class="app_input search" *ngIf="config.actions.filterBar.search">
                    <div class="input_icon">
                        <img src="assets/images/table/search_input_icon.svg">
                    </div>
                    <input type="text"
                           [value]="data && data.pagination && data.pagination.searchBy ? data.pagination.searchBy : '' "
                           [placeholder]="'toolBar.search' | translate" #searchInput
                           (keyup)="tableSearch(searchInput.value)">
                </div>

                <!-- End of Search Input -->
                <!-- All Actions for example Add New, Assign -->
                <ng-container *ngFor="let custom of config.actions.filterBar.custom">
                    <div class="border_left"></div>
                    <button [hidden]="custom.show === false"
                            class="main_buttons small_button actions orange_btn h-100"
                            [ngClass]="custom.class || 'green_btn'"
                            [disabled]="custom.disabled"
                            (click)="emitAction(custom.type,custom.data)"
                    >
                        {{custom.text | translate}}
                    </button>
                </ng-container>

                <!-- End of All Actions -->
            </div>

            <div class="search_and_buttons--right_side flex_wrap">
                <!--Export Promo Button-->
                <div class="refresh_btn  main_buttons pointer color_gray" *ngIf="config.actions.filterBar.exportPromo"
                     (click)="emitActionEvent.emit({type: 'exportPromo'})">
                    <img src="assets/images/table/import_promo.png" class="refresh_icon">
                </div>
                <!-- Export Button-->
                <div class="refresh_btn ml-10 main_buttons pointer color_gray" *ngIf="config.actions.filterBar.exportButton"
                     (click)="emitActionEvent.emit({type: 'export'})">
                    <img src="assets/images/table/export_button.svg" class="refresh_icon">
                </div>

                <!-- ADD Button  -->
                <div class="add_new_btn ml-10 main_buttons pointer" *ngIf="config.actions.filterBar.addButton"
                     (click)="emitActionEvent.emit({type: 'addNew'})">
                    <img src="assets/images/table/add_button.svg" class="add_icon">
                </div>
                <!-- ADD Button  -->
                <!-- Refresh Button  -->
                <div class="refresh_btn ml-10 main_buttons pointer" *ngIf="config.actions.filterBar.refreshButton"
                     (click)="emitRequestEvent.emit()">
                    <img src="assets/images/table/refresh_button.svg" class="refresh_icon">
                </div>
                <!-- Refresh Button  -->
                <!-- Filtration Button  -->
                <div class="position_relative" *ngIf="config.actions.filterBar.filter">
                    <div class="filter_badge" *ngIf="(data?.pagination?.filter | hasKeys)"></div>
                    <div class="refresh_btn ml-10 main_buttons pointer"
                         (click)="openTableFilterDropDownDialog('OPEN_FILTER')">
                        <img src="assets/images/table/filtration_icon.svg" class="refresh_icon">
                    </div>
                </div>
                <!-- Filtration Button  -->
                <!-- Settings Button  -->
                <div class="refresh_btn ml-10 main_buttons pointer" *ngIf="config.actions.filterBar.settings"
                     (click)="openTableSettingsDropDownDialog()">
                    <img src="assets/images/table/settings.svg" class="refresh_icon">
                </div>
                <!-- Settings Button  -->
            </div>
        </ng-container>
    </div>

    <div class="selected_item_container p-rl-80" *ngIf="config?.actions?.filterBar?.selectAll">
        <div class="sub_title_text">
            <span>{{"table.selectedCount" | translate}}:</span>
            <span class="active"> {{selectedItemCount}} из {{data?.pagination?.totalElementsCount}}</span>
        </div>
        <div class="select_texts flex_align_center">
            <div class="pointer" (click)="toggleSelectedAll(true)">
                {{"table.select_all" | translate}}
            </div>

            <div class="border_left_solid"
                 [ngClass]="[!isSelected && selectedItemCount == 0 ? 'disableCancel'  : 'pointer']"
                 (click)="toggleSelectedAll(false)">
                {{"table.cancel_select_all" | translate}}
            </div>
        </div>
    </div>


    <ng-container *ngIf="data && data.body.length && !data.loader">
        <div class="table_parent" #tableParent
             [style.height]="tableHeight && filtrationResult?.length ? tableHeight : ''"
             *ngIf="showTable"
        >
            <table class="app_table">
                <!-- Header of Table Content -->
                <thead>
                <tr>
                    <ng-container *ngFor="let head of data.header;let rowIndex=index">
                        <th *ngIf="head.status &&
                               !(data.settings && data.settings.header &&
                                data.settings.header[head.key | arrayToString]?.type === 'CHECKBOX');
                               else CheckboxTd"
                            [className]="head.tdClass || ''"
                        >
                            <div class="th middle_small_text flex_align_center">
                                <div class="name_and_sort pointer flex" (click)="makeSorting(head)">
                                    {{ head.name | translate}}
                                    <!-- Sorting arrows check if configured sorting for this column and
                                     show arrow top/down if current sorting for this column -->
                                    <app-table-column-sorting
                                            [changedDirection]="data?.pagination?.sortDirection || ''"
                                            [info]="{config: config, data: data, key: head.key | arrayToString}"
                                    ></app-table-column-sorting>
                                </div>
                                <app-table-column-search-filter
                                        [dropdownName]="dropdownName"
                                        [setting]="{head: head, data: data }"
                                        (emitNewSearchValueEvent)="columnSearchEventHandler($event)"
                                ></app-table-column-search-filter>
                            </div>
                        </th>
                        <ng-template #CheckboxTd>
                            <th *ngIf="head.status" class="pl-10 small_td">
                                <!--                                <label class="checkbox">-->
                                <!--                                    <input type="checkbox" #checkbox (click)="$event.stopPropagation()" (change)="checkAllRows(checkbox.checked);this.emitActionEvent.emit({type: 'CHECKBOX'})">-->
                                <!--                                    <span  class="checkmark"></span>-->
                                <!--                                </label>-->


                                <label class="checkbox">
                                    <input type="checkbox" #checkbox (click)="$event.stopPropagation()"
                                           (change)="checkAllRows(checkbox.checked);this.emitActionEvent.emit({type: 'CHECKBOX'})">
                                    <span class="checkmark" [ngClass]="{checked:checkbox.checked}">
                                        <img src="assets/images/table/check.svg">
                                    </span>
                                </label>


                            </th>
                        </ng-template>
                    </ng-container>
                </tr>
                </thead>
                <!-- End of Header of Table -->

                <!-- Table Data Body -->
                <tbody cdkDropList
                       class="middle_small_text"
                       [class.hidden]="data.loader"
                       [cdkDropListDisabled]="!config?.actions?.dragAndDrop"
                       (cdkDropListDropped)="drop($event)"
                >
                <ng-container *ngFor="let row of data.body; let rowIndex = index">
                    <tr cdkDrag
                        class="pointer"
                        [ngClass]="{odd:rowIndex % 2 !== 0 }"
                        (click)="emitAction('singlePage', row)"
                        (dblclick)="showSubDataForRow(row, subDataElement)">
                        <!-- With Setting Data Body -->
                        <ng-container *ngIf="data && data.settings && data.settings.header; else WithoutGlobalSetting">
                            <ng-container *ngFor="let head of data.header">
                                <td *ngIf="head.status"
                                    [ngClass]="{'pl-10': (!head.name)}"
                                    [className]="head.tdClass || ''"
                                >
                                    <div *ngIf="!data.settings.header[head.key | arrayToString]?.type; else drawWithType">
                                        <span *ngIf="!head.withParamsForArray">
                                            {{((head.key | getValueByKeyFromObject : row : head.withParams : head.withParamSymbol) | translate) || '---'}}
                                        </span>
                                        <div class="hidden_with_shadow" *ngIf="head.withParamsForArray">
                                            <span *ngFor="let item of (head.key | getArrayByKeyFromObject : row : head.withParamsForArray ); let i = index">
                                                {{item + (i === (head.key | getArrayByKeyFromObject : row : head.withParamsForArray).length - 1 ? '' : ',')}}
                                            </span>
                                        </div>
                                    </div>
                                    <ng-template #drawWithType>
                                        <app-td-type-checking
                                                (click)="$event.stopPropagation();"
                                                (emitEvent)="emitAction($event.type, $event.data, rowIndex)"
                                                [row]="row"
                                                [subTable]="subDataElement"
                                                [settings]="{key: head.key, header: data.settings.header}"
                                        ></app-td-type-checking>
                                    </ng-template>
                                </td>
                            </ng-container>
                        </ng-container>
                        <!-- End of With Setting Data Body -->
                        <!-- Without Setting Data Body -->
                        <ng-template #WithoutGlobalSetting>
                            <ng-container *ngFor="let head of data.header">
                                <td>
                                    <span *ngIf="head.name">{{(head.key | getValueByKeyFromObject : row : head.withParams : head.withParamSymbol) || '---'}}</span>
                                </td>
                            </ng-container>
                        </ng-template>
                        <!-- End of Without Setting Data Body -->
                    </tr>
                    <!-- Dropdown for Sub data of row -->
                    <div #subDataElement [hidden]="true" [attr.data-opened]="false">
                        <div class="dropdown_sub_data"
                             *ngIf="config?.actions?.dropDownRowForSubData && (subDataElement.getAttribute('data-opened') == 'true')">
                            <app-table
                                    [tableIndex]="rowIndex"
                                    [config]="config?.actions?.dropDownRowForSubData?.configs || null"
                                    [data]="(config?.actions?.dropDownRowForSubData?.data || null)
                                            | cloneTableDataConfigsObjectAndSetNewDada
                                            : ((config?.actions?.dropDownRowForSubData?.subValuesKey || []) | getDataByKey : row)"
                                    (viwInit)="subTableReadyEvent(row, subDataElement)"
                                    (emitActionEvent)="emitActionEvent.emit($event)"
                                    (emitRequestEvent)="emitRequestEvent.emit()"
                            ></app-table>
                        </div>
                    </div>
                    <!-- End of Dropdown for Sub data of row -->
                </ng-container>
                </tbody>
                <!--End of Table Data Body -->
            </table>
        </div>
        <!-- End of Table Content -->
        <!-- Pagination Content -->

        <ng-container *ngIf="data.pagination && data.pagination.totalPages">
            <div class="pagination_container flex_between_align_center" *ngIf="data.pagination.currentPage">
                <div class="pagination_container--left_side">
                    <mat-select [(ngModel)]="data.pagination.pageSize"
                                (selectionChange)="changePageSize()"
                                [panelClass]="'sort'"
                    >
                        <mat-option *ngFor="let item of [25,50,100]" [value]="item">
                            {{"table.showBy" | translate }} : {{item}}
                        </mat-option>
                    </mat-select>
                </div>
                <div *ngIf="data.pagination.totalPages > 1" class="pagination_container--right_side flex">
                    <div class="flex" *ngIf="data.pagination.currentPage > 1">
                        <img class="mr-10 pointer" src="assets/images/table/pagination/left_hard.svg"
                             (click)="changePage(1)">
                        <img class="mr-10 pointer" src="assets/images/table/pagination/left.svg"
                             (click)="changePage((data.pagination.currentPage - 1))">
                    </div>
                    <div class="flex" *ngIf="data.pagination.currentPage === 1" [ngStyle]="{opacity: '0.4'}">
                        <img class="mr-10 pointer" src="assets/images/table/pagination/left_hard.svg">
                        <img class="mr-10 pointer" src="assets/images/table/pagination/left.svg">
                    </div>
                    <div>
                        <mat-select class="mat-select-pagination middle_small_text"
                                    [value]="data.pagination.currentPage"
                                    (selectionChange)="changePage(+$event.value)"
                                    [panelClass]="'pagination_select'"
                        >
                            <mat-select-trigger>
                                {{data.pagination.currentPage}} {{"table.of" | translate}} {{data.pagination.totalPages}}
                            </mat-select-trigger>

                            <mat-option *ngFor="let item of data.pagination.totalPages | makePaginationCountArray"
                                        [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>

                    </div>
                    <div class="flex" *ngIf="data.pagination.currentPage !== data.pagination.totalPages">
                        <img class="ml-10 pointer" src="assets/images/table/pagination/right.svg"
                             (click)="changePage((data.pagination.currentPage + 1))">
                        <img class="ml-10 pointer" src="assets/images/table/pagination/right_hard.svg"
                             (click)="changePage(data.pagination.totalPages)">
                    </div>
                    <div class="flex" *ngIf="data.pagination.currentPage === data.pagination.totalPages"
                         [ngStyle]="{opacity: '0.4'}">
                        <img class="ml-10 pointer" src="assets/images/table/pagination/right.svg">
                        <img class="ml-10 pointer" src="assets/images/table/pagination/right_hard.svg">
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- End of Pagination Content -->
    </ng-container>

    <!-- Loader -->
    <app-loading [loading]="data?.loader || false"></app-loading>
    <!-- End of Loader -->
    <div class="empty_section flex_column flex_center_align_center"
         *ngIf="(config && config.emptyPage?.path && !data?.loader && !data?.body?.length)">
        <img [src]="'assets/images/table/' + config.emptyPage?.path">
        <span class="sub_title_text flex_center mt-10 pl-10">{{(config.emptyPage?.title || '') | translate}}</span>
    </div>

    <!--  FiltrationComponent  -->
    <div class="filter_settings_container"
         appRightSideDialog
         [show]="openFilterComponent"
         (animationStateEmitter)="passData = $event"
    >
        <app-table-filter [passData]="passData"
                          [data]="{
                             filterComponent: config?.filtrationComponent,
                             filtrationBody: data?.pagination?.filter,
                             optionalData: config?.specificData
                          }"
                          (click)="$event.stopPropagation()"
                          (closeModal)="openTableFilterDropDownDialog('CLOSE_FILTER',$event)"
        ></app-table-filter>
    </div>

    <div class="filter_settings_container"
         appRightSideDialog
         [show]="openSettingsComponent"
         (animationStateEmitter)="passData = $event"
    >
        <app-table-settings [passData]="passData"
                            [data]="{
                             settingsBody: data?.header
                             }"
                            (closeModal)="openSettingsComponent=false"
                            (click)="$event.stopPropagation()"
        ></app-table-settings>
    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string): unknown {
    value = value.split('+').join('');
    return `+7 (${value.slice(1,4)}) ${value.slice(4,7)}-${value.slice(7)}`;
  }

}

<div class="filter_dropdown flex_column" [@slideRightFilterComponentAnimation]="passData">
    <div class="filter_dropdown--content p-rl-30 mt-16">
        <div class="flex_between_align_center">
            <div class="title">{{'table.tool_title.filter' | translate}}</div>
            <div class="close_button pointer flex_center_align_center" (click)="close()">
                <img src="assets/images/table/close.svg">
            </div>
        </div>
        <div class="description">
            {{'table.tool_title.filter_description' | translate}}
        </div>
    </div>

    <ng-container #component ></ng-container>

    <div class="buttons_container flex_between_align_center w-100 p-rl-30">
        <button class="main_buttons small_button gray_btn w-48" (click)="removeFilter()">
            {{'table.filter.removeFilter' | translate}}
        </button>
        <button class="main_buttons small_button orange_btn w-48" (click)="save()">
            {{'table.filter.makeFilter' | translate}}
        </button>
    </div>
</div>

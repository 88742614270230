import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatNativeDateModule} from "@angular/material/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMatNativeDateModule, NgxMatTimepickerModule} from "@angular-material-components/datetime-picker";

// Components
import { ErrorMessageComponent } from './error-message.component';
import { ButtonWithLoadingComponent } from './button-with-loading.component';
import { PasswordEyeComponent } from './password-eye.component';
import {ClearValueComponent} from "./clear-value.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {TranslateModule} from "@ngx-translate/core";
import {InputComponent} from "./input/input.component";
import {AuthInputComponent} from "./auth-input/auth-input.component";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";

const components = [
    ErrorMessageComponent,
    ButtonWithLoadingComponent,
    PasswordEyeComponent,
    ClearValueComponent,
    InputComponent,
    AuthInputComponent
];
@NgModule({
  declarations: components,
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        NgxMatNativeDateModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxMatTimepickerModule,
        TranslateModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
  exports: [...components,
    MatSelectModule, MatNativeDateModule,
    MatDatepickerModule, MatAutocompleteModule, FormsModule, MatCheckboxModule,
    NgxMaskDirective, NgxMaskPipe
  ],
    providers: [
        provideNgxMask({validation: true})
    ]
})
export class FormAttributesModule { }

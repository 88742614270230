import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-password-eye',
  template: `<div class="password-eye pointer" (click)="toggleEye()">
    <img src="assets/images/icon/input/{{closed ? 'eye_opened' : 'eye_closed'}}.svg">
  </div>`,
  styles: [`
    .password-eye { position: absolute; right: 5%; top: calc(50% - 10px);  }
    .password-eye img { width: 20px }
  `]
})
export class PasswordEyeComponent {
  @Input() input: any;
  closed = true;

  toggleEye(): void {
    this.closed = !this.closed
    this.input.setAttribute('type', (this.closed) ? 'password' : 'text');
  }
}

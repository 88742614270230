import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValueByKeyFromObject'
})
export class GetValueByKeyFromObjectPipe implements PipeTransform {

  transform(
      keys: string | Array<string | number>,
      data: any,
      withParams: Array<string | Array<string | number>> | null = null,
      withParamSymbol?: string
  ): string {
    if (typeof keys === 'string') {
      return data[keys];
    } else {
      let returnValue: any = data;
      keys.forEach((key: string | number) => {
        if (!returnValue) { return; }
        returnValue = returnValue[key];
      })
      if (withParams) {

        withParams.forEach((param: Array<string | number> | string) => {
          let include = data;

          if (typeof param === 'object') {
            param.forEach((key: string | number) => {
              include = (include) ? include[key] : null;
            })
          } else {
            include = include[param];
          }

          if (include !== undefined && include !== null) {
            returnValue += (withParamSymbol ? withParamSymbol : ' ') + include;
          }
        })
      }

      return returnValue;
    }
  }

}

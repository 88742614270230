import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
//Components
import {ErrorPageComponent} from "./partials/components/global-components/error-page/error-page.component";
import {MainAuthenticatedGuard} from "./guards/main-authenticated.guard";
import {PrivacyPolicyComponent} from "./partials/components/privacy-policy/privacy-policy.component";
import {TermsConditionComponent} from "./partials/components/terms-condition/terms-condition.component";
import {AppInfoComponent} from "./partials/components/app-info/app-info.component";

const routes: Routes = [
    {
        path: 'admin',
        data:{type:'admin'},
        canLoad:[MainAuthenticatedGuard],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: '',
        pathMatch: "full",
        redirectTo: 'seller'
    },
    {
        path: 'seller',
        data:{type:'seller'},
        canLoad:[MainAuthenticatedGuard],
        loadChildren: () => import('./modules/seller/seller.module').then(m => m.SellerModule)
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
    },
    {
      path: 'terms-and-conditions',
      component: TermsConditionComponent,
    },
    {path: 'app', component: AppInfoComponent},
    {
        path: 'error',
        component: ErrorPageComponent,
    },
    {
        path: '**',
        redirectTo: 'error',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

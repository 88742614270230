<div class="app_input with_field_name" [ngClass]="className">
    <span class="normal_text">{{(configs.label || '') | translate}}</span>
    <div class="position_relative flex_center_align_center">
        <span class="label" *ngIf="configs.labelInside"
              [ngClass]="{'color_white': !(data | searchInArray : ngControl.control.value)}"
              [hidden]="!ngControl.control.value || !ngControl.control.value.toString().length">{{(configs.placeholder || '') | translate}}</span>
        <mat-select class="important_select"
                    [ngClass]="configs.class" #matSelect [aria-label]="configs.label" [id]="configs.filtrationKey || ''"
                    (opened)="registerPanelScrollEvent(matSelect)"
                    [disabled]="configs.disabled ? configs.disabled : false "
                    [panelClass]="configs?.panelClass || ''"
                    [multiple]="configs.multiple"
                    [placeholder]="(configs.placeholder || '') | translate"
                    [formControl]="ngControl.control"
        >
            <mat-option *ngIf="configs.search" disabled class="mat-select-custom-search pt-20 w-100">
                <div class="app_input w-100" *ngIf="configs.search">
                    <input type="text" class="mb-20" #searchInput placeholder="Поиск"
                           (keydown)="$event.stopPropagation()"
                           (keyup)="getData(searchInput.value, true)">
                </div>
            </mat-option>

            <mat-option *ngFor="let item of data" [value]="configs.valueByKey | getValueByKeyFromObject : item"
                        (click)="optionClick()"
            >
                {{(configs.valueToShowByKey | getValueByKeyFromObject : item) || item}}
                <span *ngIf="configs.includeValuesByKey">
                {{configs.includeValuesByKeySymbol || ''}} {{configs.includeValuesByKey | getValueByKeyFromObject : item}}
            </span>
            </mat-option>
        </mat-select>
    </div>
    <app-error-message [message]="configs.errorMessage || ''"></app-error-message>
    <app-clear-value *ngIf="configs.reset" (reset)="ngControl.control.patchValue(null)"></app-clear-value>
</div>

import { Injectable } from '@angular/core';
import {Subject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GlobalSubscriptionService {
  private subject = new Subject<any>();

  /**
   * Send message to subscribed components and services
   * @param type Type of subscription message
   * @param message
   */
  sendMessage(type: string, message?: any): void {
    this.subject.next({ message, type });
  }

  /**
   * Make Observable object for subscribing and describing
   */
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}

<div class="status_progress_bar_container">
    <div *ngFor="let item of statusesAndActiveStates; let i = index"
         class="items"
         [class]="item.class"
         [ngStyle]="{'grid-template-columns': i!==statusesAndActiveStates.length-1 ? 'repeat(3, auto)' : 'repeat(2, auto)'}"
    >
        <div class="circle flex_center_align_center">
            <span *ngIf="!item.passedStatus;else checkIcon" class="bold_800">
                {{i + 1}}
            </span>
            <ng-template #checkIcon>
                <img src="assets/images/icon/check.svg">
            </ng-template>
        </div>
        <div class="status_text">{{item.text}}</div>
        <div class="line" *ngIf="i!==statusesAndActiveStates.length-1"></div>
    </div>
</div>
<div class="privacy-policy-block flex_column w-80">
    <strong class="sub_title_text">1.1 Термины:</strong>

    <p class="middle_text">
        <span class="title_normal_text">1.1.</span> В настоящей Политике в отношении обработки персональных данных нижеуказанные термины используются в следующем значении:Политика – настоящий документ, размещенный в сети Интернет по адресу <a href = "https://click-market.ru/login/privacy-policy">https://click-market.ru/login/privacy-policy.</a>
        Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
        Обработка Персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с Персональными д анными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение Персональных данных.
        Предоставление Персональных данных – действия, направленные на раскрытие Персональных данных определенному лицу или определенному кругу лиц.
        Блокирование Персональных данных – временное прекращение Обработки Персональных данных (за исключением случаев, если Обработка необходима для уточнения Персональных данных).<br>
        Уничтожение Персональных данных – действия, в результате которых становится невозможным восстановить содержание Персональных данных в информационной системе Персональных данных и (или) в результате которых уничтожаются материальные носители Персональных данных.
        Обезличивание Персональных данных – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность Персональных данных конкретному субъекту Персональных данных.<br>
        Обезличивание Персональных данных – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность Персональных данных конкретному субъекту Персональных данных.
        Трансграничная передача Персональных данных – передача Персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.
        Сервис Click Market (Сервис) означает все страницы веб-сайта, размещенные в сети Интернет по адресу <a href ="https://click-market.ru">https://click-market.ru</a>, а также Мобильные приложения Click Market, предоставляющие доступ к базе данных о Товарах, реализуемых посредством Сервиса.<br>
        Пользователь – право- и дееспособное физическое лицо, использующее Сервис на условиях настоящего документа, приобретающее Товар исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.
        Получатель – третье лицо, указанное Пользователем в качестве получателя Товара, если получателем Товара является не Пользователь.<br>
        Заказ – осуществление Пользователем действий, направленных на заключение договора купли-продажи Товара с Продавцом, а также доставку Товара путем оформления заказа непосредственно на Сервисе.
        Товар – объект купли-продажи (вещь), не изъятый из гражданского оборота и не ограниченный в нем, являющийся предметом договора купли-продажи, заключаемого между Пользователем и Продавцом. Предметом купли-продажи могут быть продовольственные и непродовольственные Товары, информация о которых размещена на Сервисе.
        Click Market – ООО «ДРАЙВ», ОГРН/ИНН 1051637013823/1659056744, 420059, Республика Татарстан г. Казань, ул.Павлюхина д.89
    </p>


    <strong class="sub_title_text">2. Общие положения:</strong>

    <p class="middle_text">
        <span class="title_normal_text"> 2.1.</span> Политика разработана во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных физических лиц и меры по обеспечению безопасности персональных данных физических лиц.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 2.2.</span> Политика регулирует обработку персональных данных следующих физических лиц (далее – Субъекты ПД):<br>
        · Пользователей Сервиса и Получателей Товара;<br>
        · представителей/работников контрагентов Click Market;<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 2.3.</span> Обработка Персональных данных осуществляется Click Market в соответствии с Конституцией Российской Федерации, федеральными законами, в частности Федеральным законом от 27.06.2006 № 152-ФЗ «О персональных данных», подзаконными актами, другими актами, определяющими случаи и особенности обработки персональных данных, руководящими и методическими документами ФСТЭК России и ФСБ России, а также Гражданским кодексом Российской Федерации, Налоговым кодексом Российской Федерации, Трудовым кодексом Российской Федерации, Законом РФ от 07.02.1992 № 2300-1 «О защите прав потребителей», Федеральным законом от 06.12.2011 № 402-ФЗ «О бухгалтерском учете», Политикой и иными локальными актами Click Market.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 2.4.</span> Действующая редакция Политики постоянно доступна в сети Интернет по адресу <a href =" https://click-market.ru/login/privacy-policy">https://click-market.ru/login/privacy-policy.</a> Click Market вправе время от времени вносить изменения в Политику для приведения ее в соответствие с действующим законодательством Российской Федерации, а также применяемыми способами обработки и защиты персональных данных. При внесении изменений в Политику Click Market уведомляет об этом Субъектов ПД посредством опубликования новой версии Политики. Изменения вступают в силу с момента публикации, если иное прямо не установлено в момент публикации.
    </p>


    <strong class="sub_title_text">3. Цели обработки персональных данных субъектов ПД:</strong>

    <p class="middle_text">
        <span class="title_normal_text"> 3.1.</span> Обработка Персональных данных Субъектов ПД осуществляется в следующих целях: <br>
        · использование Пользователем Сервиса;<br>
        · информирование Пользователя о выполнении Заказа;<br>
        · передача Персональных данных Пользователя/Получателя Продавцу, с которым Пользователь заключает договор купли-продажи Товара, в целях исполнения такого договора;<br>
        · передача Персональных данных Пользователя/Получателя третьим лицам, в целях осуществления доставки Товара Пользователю/Получателю;<br>
        · оформление и исполнение договоров, заключаемых с Click Market;<br>
        · урегулирование претензий Пользователя;<br>
        · направление Пользователю материалов рекламного характера;<br>
        · проведение аудита и прочих внутренних исследований с целью повышения качества предоставляемых Click Market услуг;<br>
        · передача Персональных данных Пользователя кредитным организациям, участвующим в проведении операций, совершаемых Пользователем с использованием платежных карт при оплате Товаров на Сервисе и/или при возврате денежных средств Пользователю;<br>
        · в иных целях, предусмотренных нормативными правовыми актами Российской Федерации.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 3.2.</span> Состав обрабатываемых Персональных данных:<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 3.2.1</span> Персональные данные Пользователей Сервиса:<br>
        · фамилия, имя, отчество;<br>
        · пол;<br>
        · гражданство;<br>
        · дата и место рождения;<br>
        · адрес доставки, который может являться местом жительства и/или местом работы;<br>
        · данные документа, удостоверяющего личность (тип, серия, номер, кем и когда выдан);<br>
        · сведения о контактных номерах телефонов;<br>
        · сведения об адресах электронной почты, имени пользователя в сети Интернет, данные о созданном на сайте Сервиса Click Market или мобильном приложении аккаунте (учетной записи);<br>
        · сведения о платежных реквизитах;<br>
        · метаданные, данные cookies-файлов, cookies-идентификаторы, IP-адреса, сведения о браузере и операционной системе, модели мобильного устройства, а также версии программного обеспечения.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 3.2.2</span> Персональные данные Получателей Товара:<br>
        · фамилия, имя, отчество;<br>
        · пол;<br>
        · адрес доставки, который может являться местом жительства и/или местом работы;<br>
        · данные документа, удостоверяющего личность (тип, серия, номер, кем и когда выдан);<br>
        · сведения о контактных номерах телефонов.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 3.2.3</span> Персональные данные представителей/работников контрагентов Click Market:<br>
        · фамилия, имя, отчество;<br>
        · номер мобильного телефона;<br>
        · email;<br>
        · наименование работодателя;<br>
        · место нахождения работодателя;<br>
        · должность;<br>
        иные персональные данные, сообщаемые контрагентами.
    </p>


    <strong class="sub_title_text">4. Обработка персональных данных </strong>

    <p class="middle_text">
        <span class="title_normal_text">4.1.</span> Обработка Персональных данных осуществляется Click Market с согласия Субъектов ПД, выраженного в любой форме, позволяющей подтвердить факт получения согласия. Обработка персональных данных может осуществляться без согласия Субъектов ПД исключительно при наличии условий, допускающих такую обработку в соответствии со ст. 6 Федерального закона РФ № 152-ФЗ от 27.07.2006 «О персональных данных».<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.2.</span> Персональные данные могут быть получены Click Market одним из следующих способов:<br>
        · предоставлены Субъектами ПД при использовании Сервиса, в том числе путем заполнения соответствующих форм, посредством направления корреспонденции или электронных писем на адреса электронной почты Click Market и т. д.;<br>
        · предоставлены Субъектами ПД иными способами;<br>
        · получены от третьих лиц в случаях, предусмотренных настоящей Политикой.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.3.</span> Click Market вправе поручить обработку Персональных данных другому лицу на основании заключаемого с этим лицом договора. В этом случае договор с таким лицом будет содержать обязательство последнего по обеспечению всех мер по защите Персональных данных, указанных в Политике. Click Market обязуется осуществлять контроль над соблюдением этого условия.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.4.</span> Click Market осуществляет как автоматизированную, так и неавтоматизированную Обработку Персональных данных. Click Market вправе осуществлять Обработку Персональных данных Субъектов ПД следующими способами:<br>
        · сбор Персональных данных;<br>
        · запись, накопление и хранение Персональных данных;<br>
        · уточнение (обновление, изменение) Персональных данных;<br>
        · использование и передача (Предоставление, доступ) Персональных данных;<br>
        · систематизация Персональных данных;<br>
        · обезличивание Персональных данных;<br>
        · использование обезличенных Персональных данных в статистических целях;<br>
        · блокирование Персональных данных;<br>
        · уничтожение Персональных данных.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.5.</span> Click Market обеспечивает Обработку Персональных данных Субъектов ПД — граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, предусмотренных в пунктах 2, 3, 4, 8 части 1 статьи 6 Федерального закона РФ № 152-ФЗ от 27.07.2006 «О персональных данных».<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.6.</span> Click Market не осуществляет Обработку специальных категорий Персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.7.</span> Click Market может осуществляться Трансграничная передача Персональных данных. До начала осуществления Трансграничной передачи Персональных данных Click Market убедится в том, что иностранным государством, на территорию которого осуществляется передача Персональных данных, обеспечивается адекватная защита прав Субъектов ПД. Трансграничная передача Персональных данных на территории иностранных государств, не обеспечивающих адекватной защиты прав Субъектов ПД, может осуществляться в случаях:<br>
        · наличия письменного согласия Субъекта ПД;<br>
        · предусмотренных международными договорами Российской Федерации;<br>
        · предусмотренных федеральными законами, если это необходимо в целях защиты основ конституционного строя Российской Федерации, обеспечения обороны страны и безопасности государства;<br>
        · исполнения договора, стороной которого является Субъект ПД;<br>
        · защиты жизни, здоровья, иных жизненно важных интересов Субъекта ПД или других лиц при невозможности получения согласия в письменной форме Субъекта ПД.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 4.8.</span> К Обработке Персональных данных допускаются работники Click Market, в должностные обязанности которых входит Обработка Персональных данных.
    </p>

    <strong class="sub_title_text">5. Принципы обработки персональных данных субъектов ПД:</strong>
    <p class="middle_text">
        <span class="title_normal_text">5.1.</span> При Обработке Персональных данных Click Market придерживается следующих принципов:<br>
        · обработка Персональных данных осуществляется на законных основаниях;<br>
        · персональные данные не раскрываются третьим лицам и не распространяются без согласия Субъекта ПД за исключением случаев, предусмотренных Политикой и действующим законодательством Российской Федерации;<br>
        · обработка Персональных данных ограничивается достижением конкретных, заранее определенных и законных целей;<br>
        · объединение баз данных, в том числе баз данных, содержащих Персональные данные, обработка которых осуществляется в целях, несовместимых между собой, не допускается;<br>
        · обрабатываемые Персональные данные подлежат уничтожению или обезличиванию при отзыве согласия на их обработку Субъектом ПД, по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено действующим законодательством Российской Федерации;<br>
        · содержание и объем обрабатываемых Персональных данных соответствуют заявленным целям обработки. Обрабатываемые Персональные данные не являются избыточными по отношению к заявленным целям Обработки;<br>
        · при Обработке Персональных данных обеспечиваются точность Персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям Обработки Персональных данных;<br>
        · хранение Персональных данных осуществляется в форме, позволяющей определить Субъекта ПД не дольше, чем этого требуют цели Обработки Персональных данных, если срок хранения Персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является Субъект ПД.
    </p>


    <strong class="sub_title_text">6. Сроки обработки персональных данных субъектов ПД:</strong>

    <p class="middle_text">
        <span class="title_normal_text">6.1.</span> Сроки Обработки (хранения) Персональных данных определяются исходя из целей Обработки Персональных данных:<br>
        · Персональные данные Субъектов ПД, указанных в п. 3.2.1 Политики, – в течение всего срока использования Сервиса, а также в течение 15 лет с момента последнего использования Сервиса или исполнения последнего Заказа в зависимости от того, что наступит позднее;<br>
        · Персональные данные Субъектов ПД, указанных в п. 3.2.2 Политики, – в течение 15 лет с момента исполнения последнего Заказа;<br>
        · Персональные данные Субъектов ПД, указанных в п. 3.2.3 Политики, – в течение срока действия договора, заключенного с контрагентом, а также в течение 15 лет с момента его прекращения;
    </p>


    <strong class="sub_title_text">7. Меры по защите персональных данных субъектов ПД:</strong>

    <p class="middle_text">
        <span class="title_normal_text">7.1.</span> При Обработке Персональных данных Click Market принимает необходимые правовые, организационные и технические меры для защиты Персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, неправомерного предоставления, распространения Персональных данных, а также от иных неправомерных действий.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 7.2.</span> Для обеспечения безопасности Обработки Персональных данных Субъектов ПД Click Market предприняты в том числе следующие меры:<br>
        · приказом назначено лицо, ответственное за организацию Обработки Персональных данных;<br>
        · разработаны локальные акты по вопросам Обработки Персональных данных;<br>
        · работники ознакомлены с положениями законодательства Российской Федерации о Персональных данных, а также локальными актами по вопросам Обработки и защиты Персональных данных;<br>
        · реализовано разграничение прав доступа работников к Персональным данным;<br>
        · осуществляется регулярный контроль соответствия Обработки Персональных данных законодательству Российской Федерации и локальным актам Click Market.
    </p>

    <strong class="sub_title_text">8. Права субъектов ПД: </strong>

    <p class="middle_text">
        <span class="title_normal_text">8.1.</span> Субъект ПД имеет право запрашивать информацию, касающуюся Обработки его Персональных данных, в порядке, предусмотренном нормативными правовыми актами Российской Федерации.<br>
    </p>
    <p class="middle_text">
        <span class="title_normal_text"> 8.2.</span> Субъект ПД имеет право требовать внесения изменений в обрабатываемые Персональные данные в целях их актуализации.<br>
    <p class="middle_text">
        <span class="title_normal_text"> 8.3.</span> Субъект ПД имеет право во всякое время полностью или частично отозвать данное им согласие на Обработку Персональных данных.<br>
    </p>

    <strong class="sub_title_text">9. Взаимодействие: </strong>
    <p class="middle_text">
        <span class="title_normal_text">9.1.</span> Любые обращения и требования, включая требования, указанные в Разделе 8 Политики, направляются Субъектом ПД в простой письменной форме с указанием реквизитов, предусмотренных действующим законодательством Российской Федерации о персональных данных, по адресу 420059, Республика Татарстан г. Казань, ул. Павлюхина д. 89 или по электронной почте <a href ="hello@click-market.ru">hello&#64;click-market.ru.</a> Дата публикации 20.12.2022 г.</p>

</div>

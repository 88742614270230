import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild} from '@angular/core';
// Constants
import {
  HeaderSettingItem,
  HeaderSettings
} from "@intrerfaces/user/components/table-component.interface";

@Component({
  selector: 'app-td-type-checking',
  templateUrl: './td-type-checking.component.html',
  styleUrls: ['./td-type-checking.component.scss']
})
export class TdTypeCheckingComponent implements AfterViewInit {
  @ViewChild('imagePopup') private imagePopup!: ElementRef;
  @Input() row: any = null;
  @Input() subTable!: HTMLElement;
  @Input() settings: {key: string | Array<string | number>, header: HeaderSettings} | undefined = undefined;
  @Output() emitEvent = new EventEmitter<{ type: string, data: { [key: string]: any } }>();

  realSettings: HeaderSettingItem | null = null;
  realKey: string = '';

  constructor(private renderer: Renderer2) { }


  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.settings) {
        this.realKey = (typeof this.settings.key === 'object') ? this.settings.key.join(',') : this.settings.key;
        this.realSettings = this.settings.header[this.realKey]
      }
    }, 100)
  }

  emitNewEvent(type: any, data: any = {}): void {
    this.emitEvent.emit({
      type,
      data: { id: this.row?.id, ...data, key: this.settings?.key}
    })
  }

  setValue(value: any): void {
    if (this.realSettings) {
      this.realSettings.data.collected[this.row.id] = value;
    }
  }

  mouseEnter(event: any) {
    const myElement = this.imagePopup.nativeElement;
    if (event.y > 450) {
      this.renderer.setStyle(myElement, 'top', 300 - event.y +'px');
    } else {
      this.renderer.setStyle(myElement, 'top', 0 +'px');
    }
  }


}

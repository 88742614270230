import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[yandexMap]'
})
export class YandexMapDirective implements OnInit {
    @Input('coordinates') set coordinatesData(data: { latitude: number, longitude: number }) {
        this.coordinates = data;
        this.updateMap();
    }

    ymaps: any;
    map: any;
    coordinates!: { latitude: number, longitude: number };

    constructor(private elementRef: ElementRef) {
        this.ymaps = (window as any)['ymaps'];
    }


    ngOnInit(): void {
        this.displayMap()
    }

    displayMap(): void {
        this.ymaps.ready().then(() => {
            this.map = new this.ymaps.Map(this.elementRef.nativeElement, {
                center: [this.coordinates.latitude, this.coordinates.longitude],
                zoom: 10,
                controls: ['fullscreenControl']
            });

            this.addPlaceMark(this.coordinates.latitude, this.coordinates.longitude)
        });
    }

    addPlaceMark(lat: number, long: number): void {
        const placeMark = new this.ymaps.Placemark([lat, long]);
        this.map.geoObjects.add(placeMark);
    }

    updateMap(): void {
        if (!this.map) { return; }
        this.map.geoObjects.removeAll();
        this.map.setCenter([this.coordinates.latitude, this.coordinates.longitude]);
        this.addPlaceMark(this.coordinates.latitude, this.coordinates.longitude)
    }
}
<div class="download_app_container">
    <div class="navbar flex_center_align_center w-100">
        <img src="assets/images/icons/download_app/navbar-logo.svg" class="flex_center_align_center pointer"
             (click)="backToCatalog()">
    </div>

    <div class="scrollable_content">
        <div class="mt-16 title_description_container flex_column_center_align_center">
            <div class="title text_size_24 bold_800">
                {{'download_app.for_all_devices' | translate}}
            </div>
            <p class="description text_center middle_text">
                {{'download_app.your_personal_market_place' | translate}}
            </p>
        </div>

        <div class="download_icons">
            <div class="application_icon pointer flex_center_align_center"
                 *ngFor="let link of downloadLinksConfig"
                 (click)="downloadApplicationLink(link.link)">
                <img [src]="link.image">
            </div>
        </div>

        <div class="back_to_catalog">
            <button class="main_buttons bold_700 text_normal  w-100" (click)="backToCatalog()">
                {{"download_app.back_to_catalog" | translate }}
            </button>
        </div>


        <div class="mobile_phone">
            <img src="assets/images/icons/download_app/phone.png">
        </div>
    </div>
</div>

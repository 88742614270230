export const formMessages: { [key: string]: { [key: string]: string } } = {
    email: {
        pattern: 'Invalid email format',
        required: 'Email is required'
    },
    password: {
        required: 'Password is required'
    },
    repeatPassword: {
        required: 'It is required to confirm password',
        mustMatch: 'Password confirmation not match'
    },
    // Role Management Module
    description: {
        required: 'Описание обязательно'
    },
    name: {
        required: 'Имя обязательно'
    },
    firstName: {
        required: 'First name is required'
    },
    lastName: {
        required: 'Last name is required'
    },
    phoneNumber: {
        required: 'Phone number is required',
        pattern: 'Phone number format is not valid'
    },
    flowerIds: {
        required: 'Flowers is required',
    },
    deliveryPrice: {
        required: 'Delivery Price is required',
    },
    radius: {
        required: 'Radius is required',
    },
    averageDeliveryTime: {
        required: 'Hour is required',
    },
    totalUsageAmount: {
        required: 'Общая сумма использования обязательно',
    },
    perUserUsageAmount: {
        required: 'Сумма использования для каждого пользователя обязательно'
    },
    amount: {
        required: 'Скидка обязательно',
        pattern: 'Формат суммы недействителен',
        min : 'Вы не можете вводить числа меньше 1',
        max : 'Вы не можете вводить числа больше 100'
    },
    amountType: {
        required: 'Тип скидки обязательно'
    },
    promo: {
        required: 'Промокод обязательно',
        minlength:'Длина промокода должна быть от 4 до 20',
        maxlength:'Длина промокода должна быть от 4 до 20'
    },
    maxSum: {
        required: 'Максимальное сумма обязательно',
    },
    minSum: {
        required: 'Минимальная сумма обязательно',
    },
    permissionId :{
        required: 'Должность для сотрудника обязательно',
    }

}

<div class="seller_shop_container h-100">
    <div class="p-rl-80 middle_text">{{seller?.firstName || seller?.phoneNumber}}</div>
    <app-table
            [config]="tableConfigs"
            [data]="tableData"
            (readyEvent)="getSellerShops()"
            (emitRequestEvent)="getSellerShops()"
            (emitActionEvent)="handleEvent($event)"
    >
    </app-table>
</div>

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDateRangePicker} from "@angular/material/datepicker";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ValidationPatterns} from "@intrerfaces/validators/validation-patterns";

@Component({
  selector: 'app-mat-range-datepicker',
  templateUrl: './mat-range-datepicker.component.html',
  styles: [`
    .relative {
      position: relative;
      ::ng-deep {
        .standard {
          bottom: 18px;
        }
      }
    }
    .unset {
      border: unset !important;
      padding: 1px 10px 1px 10px!important;
    }
    input{
      background-color: unset !important;
    }
  `]
})
export class MatRangeDatepickerComponent implements OnChanges, AfterViewInit {
  @Input() label: string = '';
  @Input() value?: Array<string | null>;
  @Input() defaultValue?: Array<Date>;
  @Input() key?: {start: string, end: string, title: string};
  @Input() submit = false;
  @Output() resetForm = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();
  @ViewChild('picker') picker!: MatDateRangePicker<any>;

  dateRangeForm = new UntypedFormGroup({
    startDate: new UntypedFormControl('', [Validators.maxLength(10)]),
    endDate: new UntypedFormControl('', [Validators.maxLength(10)]),
  });

  startDateValidation = true;
  endDateValidation = true;

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.defaultValue?.length) {
        this.dateRangeForm.patchValue({
          startDate: this.defaultValue[0],
          endDate: this.defaultValue[1]
        })
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes.value?.currentValue && !(changes.value.currentValue[0])) {
        if (this.picker) {
          this.dateRangeForm.reset();
          this.picker.select(null)
        }
      } else if (changes.value && this.value?.length) {
        this.dateRangeForm.setValue({
          startDate: new Date(this.value[0] || ''),
          endDate: new Date(this.value[1] || '')
        })
      }
    }, 10)
  }

  keyup(event: any, type: string) {
    this.checkValidation(event,type,true)
  }

  checkValidation(event: any, type: string, keyup = false): void {
    const value = keyup ? event.target.value : event.targetElement.value
    let regex = new RegExp(ValidationPatterns.date)
    if (type === 'startDate') {
      this.startDateValidation = regex.test(value)
    } else {
      this.endDateValidation = regex.test(value)
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button-with-loading',
  template: `
    <button [ngClass]="buttonClass + ( submit ? ' loader-padding' : '')" [ngStyle]="buttonStyle"
            [disabled]="submit || disabled">
          <span *ngIf="!submit; else Loading">
           <ng-container *ngIf="buttonText || valueToShow">
            <span class="middle_text">{{ buttonText | translate}}</span>
            <span class="middle_text">{{ valueToShow }}</span>
          </ng-container>
            
          <ng-container *ngIf="buttonIcon">
            <img [src]="'assets/images/icon/button/' + buttonIcon + '.svg'" class="flex_center_align_center">
          </ng-container>
          </span>
      <ng-template #Loading>
        <img class="main_buttons--loader" src="assets/images/icon/loader-gif.svg">
      </ng-template>
    </button>`,
})
export class ButtonWithLoadingComponent implements OnInit {
  @Input() buttonText: string = '';
  @Input() buttonClass: string = '';
  @Input() buttonStyle: {[key: string]: string} = {};
  @Input() submit: boolean = false;
  @Input() disabled: boolean = false;
  @Input() buttonIcon: string = '';
  @Input() valueToShow: any;

  constructor() { }

  ngOnInit(): void { }

}

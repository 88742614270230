import {PaginationResponse} from "@intrerfaces/global/request.interface";
import {CityInterface} from "@intrerfaces/user/modules/city.interface";

export interface ShopInterface {
    id?: number,
    averageRate: number;
    shopName: string,
    fullAddress: string,
    approvalStatus: ApprovalStatusEnum,
    reasonNote?: string,
    description: string,
    phoneNumber: string,
    email: string,
    telegram: string,
    whatsApp: string,
    requisite: Requisite,
    organisationName: string,
    inn: string,
    kpp: string,
    ogrn: string,
    fullNameOfAdmin: string,
    registrationAddress: string,
    postalAddress: string,
    taxSystem: TaxSystem,
    bik: string,
    bankName: string,
    bankAccountNumber: string,
    city: CityInterface,
    imageUrl?: string,
    coverImageUrl?:string,
    productsCount: number,
    activeProductsCount: number,
    averageDeliveryTime: number,

    //for local Use
    disabled?: boolean,
    isArchived?: boolean
}

export interface ShopWithDeliveryWorkScheduleInterface extends ShopInterface {
    deliveryWorkSchedules: Array<ShopDeliveryWorkSchedule>
}

export interface ShopPaginationInterface extends PaginationResponse {
    content: Array<ShopInterface>
}

export interface AdminSellerShopInterface extends ShopInterface {
    approvalStatus: ApprovalStatusEnum,
}

export enum Requisite {
    ip = 'IP',
    ooo = 'OOO',
    selfEmployed = 'SELF_EMPLOYED'
}

export enum TaxSystem {
    general = 'GENERAL',
    simplified = 'SIMPLIFIED '
}


export interface ShopDeliveryWorkScheduleBase {
    startTime?: string,
    endTime?: string,
    isWorkingDay: boolean,
    id?: number
}

export interface ShopDeliveryWorkSchedule extends ShopDeliveryWorkScheduleBase {
    dayOfWeek: WeekDaysEnum
}

export enum WeekDaysEnum {
    monday = 'MONDAY',
    tuesday = 'TUESDAY',
    wednesday = 'WEDNESDAY',
    thursday = 'THURSDAY',
    friday = 'FRIDAY',
    saturday = 'SATURDAY',
    sunday = 'SUNDAY'
}

export enum ApprovalStatusEnum {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED'
}

import {AfterViewInit, Component, Input} from '@angular/core';
import {SettingsStateService} from "@services/components/settings-state.service";

@Component({
    selector: 'app-settings-base',
    template: '',
    styles: []
})
export class SettingsBaseComponent implements AfterViewInit {
    @Input() settingsBody!: Array<{[key: string]: number | string | boolean}>;
    @Input() optionalData!: any;
    ready!: boolean;
    checked: boolean = true;

    constructor() { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.ready = true;
            this.settingsBody = this.settingsBody.filter((item) => item.name)
        },10)
    }

}

export const SuccessMessages: { [key: string]: string } = {
    login: 'Successfully Signed In',
    shopAssigned: 'Магазин прикреплён к заказу',
    statusChanged: 'Статус успешно обновлен',
    orderAccepted: 'Заказ принят',
    deliveryOn: 'Дата доставки обновлён',
    deliveryAddressChange: 'Адрес доставки обновлён',
    copied: 'Успешно скопирован',
    notificationSend: 'Уведомления успешно отправлены'
};

export const ErrorMessages: { [key: string]: string } = {
    global: 'Извините что то произошло не так',
    // Contract Route creation
    pageCanNotBiggerThenTotal: 'Введенная страница не может превышать общее количество страниц',
    pageCanNotBe: 'Пожалуйста, введите действительную страницу',
    notFound: 'Не найдено'
};

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HeadType, TableData} from "@intrerfaces/user/components/table-component.interface";

@Component({
  selector: 'app-table-column-search-filter',
  templateUrl: './column-search-filter.component.html',
  styleUrls: []
})
export class ColumnSearchFilterComponent implements OnChanges {

  @Input() setting: {head: HeadType, data: TableData} | null = null;
  @Input() dropdownName = '';
  @Output() emitNewSearchValueEvent = new EventEmitter<{key: string, value: string}>();
  searchAccess = false;
  opened = false;
  key: string = '';
  searchValue: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dropdownName && this.searchAccess) {
      if (this.dropdownName !== this.key) { this.opened = false; }
    } else if (this.setting) {
      const key = ( typeof this.setting.head.key === 'string') ? this.setting.head.key : typeof this.setting.head.key.join(',');
      if (this.setting.data && this.setting.data.settings?.search?.includes(key)) {
        this.searchAccess = true;
        this.key = key;
      }
    }
  }

  addSearch(): void {
    this.emitNewSearchValueEvent.emit({key: this.key, value: this.searchValue})
  }

  openClose(): void {
    this.opened = !this.opened;
  }

}

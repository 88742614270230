import {
    Component,
    ComponentFactoryResolver,
    ViewContainerRef,
    Inject,
    ViewChild,
    AfterViewInit, ComponentRef
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-standard-dialog',
    templateUrl: './standard-dialog.component.html',
    styles: [`.modal_content {position: relative}`]
})
export class StandardDialogComponent implements AfterViewInit {
    @ViewChild('component',  {read: ViewContainerRef}) componentContainer: ViewContainerRef | undefined;
    component: ComponentRef<any> | undefined;
    pending = false;
    disabled = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            component: any,
            configs?: {
                marginTop?: boolean,
                title: string,
                icon?: {
                    value : string,
                    type : string,
                    color?:string
                },
                button?: {
                    text: string,
                    class?: string,
                    type?: string,
                    hidden?: boolean,
                    full?:boolean
                },
                disabled?: boolean,
                description?: string,
                data?: any,
                cancelButton?: {
                    text?: string,
                    hidden?: boolean
                },
                closeButton?: false
            }
        },
        private componentFactoryResolver: ComponentFactoryResolver,
        private dialogRef: MatDialogRef<any>
    ) {}

    ngAfterViewInit() {
        setTimeout(() => {
            if (!this.data.component) { return; }
            this.component = this.componentContainer?.createComponent(
                this.componentFactoryResolver.resolveComponentFactory(this.data.component)
            )
            if (this.component) {
                
                this.component.instance.data = this.data.configs?.data;

                this.component?.instance.closeModal.subscribe((data: any) => {
                    this.dialogRef.close(data)
                })
                this.component?.instance.loading?.subscribe((start: boolean) => {
                    this.pending = start;
                })
                this.component?.instance.disabled?.subscribe((disabled: boolean) => {
                    this.disabled = disabled;
                })
                this.component?.instance.changeButtons?.subscribe((config: { text: string, class?: string }) => {
                    if (this.data.configs) {
                        this.data.configs.button = config;
                    }
                })
            }
        }, 10)
    }

    save(type: string = ''): void {
        if (type === 'close') {
            this.dialogRef.close(true);
        } else if (this.component) {
            this.component.instance.save();
        } else {
            this.dialogRef.close(true);
        }
    }

    close(): void {
        this.dialogRef.close(null);
    }
}

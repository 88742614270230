import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {ToastrModule} from "ngx-toastr";
import {MatSelectModule} from "@angular/material/select";
import {RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings} from "ng-recaptcha";
import {environment} from "../environments/environment";

// Modules
import {GlobalComponentsModule} from "./partials/components/global-components/global-components.module";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {ComponentsModule} from "./modules/admin/user/partials/components/components.module";
import {DirectivesModule} from "./partials/directives/directives.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {MatDialogModule} from "@angular/material/dialog";
import {ReactiveFormsModule} from "@angular/forms";

// Services
import {RequestInterceptorService} from "@services/request/interceptors/request-interceptor.service";

// Components
import {AppComponent} from './app.component';
import {PrivacyPolicyComponent} from './partials/components/privacy-policy/privacy-policy.component';
import {TermsConditionComponent} from './partials/components/terms-condition/terms-condition.component';
import {SingleComponent} from './modules/admin/user/modules/seller/single/single.component';
import {
    ReasonNoteDialogComponent
} from './modules/admin/user/modules/seller/single/reason-note-dialog/reason-note-dialog.component';
import {AppInfoComponent} from "./partials/components/app-info/app-info.component";



@NgModule({
    declarations: [
        AppComponent,
        PrivacyPolicyComponent,
        TermsConditionComponent,
        SingleComponent,
        ReasonNoteDialogComponent,
        AppInfoComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        GlobalComponentsModule,
        MatDialogModule,
        HttpClientModule,
        MatSelectModule,
        HttpClientJsonpModule,
        ToastrModule.forRoot({
            timeOut: 4000
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'ru'
        }),
        MatAutocompleteModule,
        ComponentsModule,
        DirectivesModule,
        ReactiveFormsModule,
    ],
    providers: [
        // Handle all requests in all project, detect and show error formMessages, add Authorization token and etc...
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptorService,
            multi: true
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey,
            } as RecaptchaSettings
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePickerFormatDirective} from "./dates/date-picker-format.directive";
import {YandexMapDirective} from "./map/yandex-map.directive";
import {RightSideDialogDirective} from "./sideBar/right-side-dialog.directive";

const directives = [DatePickerFormatDirective, YandexMapDirective, RightSideDialogDirective]

@NgModule({
    declarations: directives,
    imports: [
        CommonModule
    ],
    exports: directives
})
export class DirectivesModule {
}

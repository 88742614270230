import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isBoolean'
})
export class VariableTypeCheckPipe implements PipeTransform {

    transform(value: any): boolean {

        return value === true
    }

}

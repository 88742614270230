import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent {
    @Input() title!: string;
    @Input() selectedIndex!: any;
}
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchInArray'
})
export class SearchInArrayPipe implements PipeTransform {


    transform(
        data: Array<any>,
        value: any): boolean {
        if (Array.isArray(value)) {
            return value.some(id => data.some(item => item.id === id));
        }
        return !!data.find(item => item.id === value)

    }

}

import {Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-message',
  template: '<div class="small_text" *ngIf="message" [ngClass]="{active: message}">*{{message | translate}}</div>',
  styles: [`
    .small_text {
      top: -17px;
      position: absolute;
    }
  `]
})
export class ErrorMessageComponent {
  @Input() message: string = '';
}

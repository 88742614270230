import {Injectable} from "@angular/core";
import {StateInLocalStorageInterface} from "@intrerfaces/user/components/state-in-local-storage.interface";

@Injectable({
    providedIn: 'root'
})
export class CurrentStateInLocalstorageService {
    localStorageKey = 'STATE_MANAGEMENT';
    activeTab = 'ACTIVE_ROUTE';

    removeAll(): void {
        localStorage.removeItem(this.localStorageKey)
    }

    saveStateInLocalStorageForTable(key: string, dataToSave: StateInLocalStorageInterface = {pagination: {}, settings: [],quickFilter:{}}): void {
        const data = this.parseDataFromLocalStorage()
        data[key] = dataToSave;
        localStorage.setItem(this.localStorageKey, JSON.stringify(data))
    }

    getStateFromLocalStorageForTable(key: string): any {
        const parsedGlobalState = this.parseDataFromLocalStorage()
        return (parsedGlobalState[key]) ? parsedGlobalState[key] : {}
    }

    saveActiveTab(activeRoute: string): void {
        const objectFromLocalStorage = this.parseDataFromLocalStorage();
        objectFromLocalStorage[this.activeTab] = activeRoute;
        localStorage.setItem(this.localStorageKey, JSON.stringify(objectFromLocalStorage))
    }

    getActiveTab(): any {
        const objectFromLocalStorage = this.parseDataFromLocalStorage()
        return objectFromLocalStorage[this.activeTab]
    }

    parseDataFromLocalStorage(): {[key: string]: StateInLocalStorageInterface | number | string } {
        const json = localStorage.getItem(this.localStorageKey);
        let objectFromLocalStorage: {[key: string]: StateInLocalStorageInterface}  = {}
        if (json) {
            objectFromLocalStorage = JSON.parse(json);
        }
        return objectFromLocalStorage
    }

}
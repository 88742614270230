import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDataByKey'
})
export class GetDataByKeyPipe implements PipeTransform {

  transform(
      keys: Array<string | number>,
      data: any
  ): Array<any> {
      let returnValue: any = data;
      keys.forEach((key: string | number) => {
        if (!returnValue) { return; }
        returnValue = returnValue[key];
      })
      return returnValue;
  }

}

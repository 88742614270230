<div class="settings_dropdown" [@slideRightFilterComponentAnimation]="passData">
    <div class="settings_dropdown--content p-rl-30 mt-16">
        <div class="flex_between">
            <div class="sub_title_text">{{'table.tool_title.settings' | translate}}</div>
            <div class="close pointer flex_center_align_center" (click)="close()">
                <img src="assets/images/table/close.svg">
            </div>
        </div>
        <div class="description">
            {{'table.tool_title.settings_description' | translate}}
        </div>
    </div>

    <div class="settings_container p-rl-30" *ngIf="ready">
        <div [ngClass]="i !== data.settingsBody.length - 1 ? 'border_bottom' : ''"
             class="settings_container--item flex_between_align_center"
             *ngFor="let item of data.settingsBody; let i = index">
            <span *ngIf="item['name']" class="middle_text bold">{{(item['name'].toString()) | translate}}</span>
            <div *ngIf="item['name']" class="app_input toggle_input">
                <mat-slide-toggle
                        [checked]="item['status'] | isBoolean"
                        (change)="saveState(i,$event)"></mat-slide-toggle>
            </div>
        </div>
    </div>


</div>

import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
//Services
import {RequestMethodsService} from "@services/request/request-methods.service";
import {DialogService} from "@services/components/dialog.service";

//Constants
import {endpointsConstant} from "@intrerfaces/global/endpoints.constant";
import {AdminSellerInterface} from "@intrerfaces/user/modules/admin-seller.interface";
import {TableConfigs, TableData} from "@intrerfaces/user/components/table-component.interface";
import {AdminSellerShopInterface, ApprovalStatusEnum} from "@intrerfaces/user/modules/shop.interface";

//Components
import {ReasonNoteDialogComponent} from "./reason-note-dialog/reason-note-dialog.component";
import {RequestMethodRoleEnum} from "@intrerfaces/user/request-methods/request-method-role.enum";

@Component({
    selector: 'app-single',
    templateUrl: './single.component.html',
    styleUrls: ['./single.component.scss']
})
export class SingleComponent {
    id!: number;
    seller!: AdminSellerInterface;
    tableConfigs: TableConfigs = {
        endpointName: endpointsConstant.shop,
        tableEnum: 'SINGLE_SELLER_SHOP',
        styles: {
            classes: ['table_with_big_pudding_and_shadowed_rows', 'table_with_pagination']
        },
        actions: {
            filterBar: {
                refreshButton: true,
                settings: true,
            },
            sorting: true,
        },
        emptyPage: {title: "empty", path: "empty_page.svg"},
    };
    tableData: TableData = {
        settings: {
            header: {
                approvalStatus: {
                    class:'small_container',
                    type: 'APPROVE_DECLINE',
                    data: {
                        [ApprovalStatusEnum.PENDING]: {
                            text: 'На модерации', color: '#E89749',
                            selectableValues: [
                                {text: 'Одобрен', value: 'APPROVED'},
                                {text: 'Отклонён', value: 'DECLINED'}
                            ]
                        },
                        [ApprovalStatusEnum.APPROVED]: {text: 'Подтвержден', color: 'var(--text-green-color)'},
                        [ApprovalStatusEnum.DECLINED]: {
                            text: 'Отклонён', color: '#C5493D',
                            selectableValues: [
                                {text: 'Одобрен', value: 'APPROVED'},
                            ]
                        },
                    }
                }
            },
            sorting: {
                id: 'ID'
            }
        },
        header: [
            {id: 1, name: 'shop.id', key: 'id'},
            {id: 2, name: 'shop.phoneNumber', key: 'phoneNumber'},
            {id: 3, name: 'shop.shopName', key: 'shopName'},
            {id: 4, name: 'shop.fullAddress', key: 'fullAddress'},
            {id: 5, name: 'shop.email', key: 'email'},
            {id: 6, name: 'shop.confirmShopStatus', key: 'approvalStatus'},
        ],
        loader: false,
        body: [],
        pagination: {filter: {}}
    }


    constructor(
        private activatedRoute: ActivatedRoute,
        private requestService: RequestMethodsService,
        private dialogService: DialogService
    ) {
        this.id = this.activatedRoute.snapshot.params.id;
        this.getSellerShops();
        this.getSeller()
    }


    getSeller(): void {
        this.requestService.get(`${endpointsConstant.seller}/${this.id}`
        ).subscribe((response: AdminSellerInterface) => {
            this.seller = {...response};
        }, () => this.tableData.loader = false)
    }

    getSellerShops(): void {
        this.tableData.loader = true;
        this.requestService.get(`${endpointsConstant.seller}/${this.id}/${endpointsConstant.shops}`,RequestMethodRoleEnum.admin
        ).subscribe((response: Array<AdminSellerShopInterface>) => {
            this.tableData.body = [...response];
            this.tableData.loader = false
        }, () => this.tableData.loader = false)
    }

    changeShopStatusRequest(data: any): void {
        const dataToSend: any = {approvalStatus: data.value}
        if (data.reasonNote) {
            dataToSend['reasonNote'] = data.reasonNote
        }

        this.requestService.put(
            `${endpointsConstant.shop}/${data.id}/${endpointsConstant.status}`,
            {...dataToSend}).subscribe(() => {
            this.getSellerShops()
        }, () => this.getSellerShops())
    }


    openReasonNoteDialog(data: any): void {
        const dialog = this.dialogService.openStandardDialog(ReasonNoteDialogComponent, {}, {
            title: 'shop.reasonNote',
            cancelButton: {
                hidden: true
            },
            closeButton: true
        })
        dialog.afterClosed().subscribe((response) => {
            if (response) {
                this.changeShopStatusRequest({...data, reasonNote: response})
            }
        })
    }

    handleEvent(event: any): void {
        const type = event.type;
        if (type == 'APPROVE_DECLINE') {
            if (event.data.value == ApprovalStatusEnum.DECLINED) {
                this.openReasonNoteDialog(event.data)
            } else {
                this.changeShopStatusRequest(event.data)
            }
        }
    }

}

import {NgModule} from '@angular/core';

//Modules
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {GoogleMapsModule} from "@angular/google-maps";
import {MatIconModule} from "@angular/material/icon";
import {PipesModule} from "../../../../../partials/pipes/pipes.module";
import {FormAttributesModule} from "../../../../../partials/components/form-attributes/form-attributes.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatMenuModule} from "@angular/material/menu";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {
    NgxMatMomentModule,
    NgxMatMomentAdapter,
    NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular-material-components/moment-adapter';
import {
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NGX_MAT_DATE_FORMATS,
    NgxMatDateAdapter
} from '@angular-material-components/datetime-picker';

// Components
import {TableComponent} from "./table/table.component";
import {TdTypeCheckingComponent} from './table/td-type-checking/td-type-checking.component';
import {ContainerTitleAndToolbarComponent} from './container-title-and-toolbar/container-title-and-toolbar.component';
import {ColumnSortingComponent} from './table/column-sorting/column-sorting.component';
import {ColumnSearchFilterComponent} from './table/column-search-filter/column-search-filter.component';
import {StandardDialogComponent} from './dialogs/standard-dialog/standard-dialog.component';
import {CustomDialogComponent} from './dialogs/custom-dialog/custom-dialog.component';
import {FilterComponent} from './table/filter/filter.component';
import {LoadingComponent} from './loading/loading.component';
import {FiltrationBaseComponent} from './filtration-base/filtration-base.component';
import {MatSelectWithScrollComponent} from './mat-select-with-scroll/mat-select-with-scroll.component';
import {SelectAddressOnMapComponent} from './select-address-on-map/select-address-on-map.component';
import {SettingsComponent} from './table/settings/settings.component';
import {SettingsBaseComponent} from "./settings-base/settings-base.component";
import {MatRangeDatepickerComponent} from "./mat-range-datepicker/mat-range-datepicker.component";
import {ClearValueComponent} from "./clean-button/clear-value.component";
import {DialogDeleteComponent} from './table/dialog-delete/dialog-delete.component';
import {QuickFilterComponent} from './table/quick-filter/quick-filter.component';
import {TitleContainerComponent} from "./title-container/title-container.component";
import {DirectivesModule} from "../../../../../partials/directives/directives.module";
import {StatusProgressBarComponent} from "./status-progress-bar/status-progress-bar.component";
import {StarRatingComponent} from "./star-rating/star-rating.component";
import { StarSvgComponent } from './star-rating/star-svg/star-svg.component';

export const CUSTOM_MOMENT_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD.MM.YYYY, HH:mm:ss',
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY"
    }
};

@NgModule({
    declarations: [
        TableComponent, TdTypeCheckingComponent,
        ContainerTitleAndToolbarComponent,
        ColumnSortingComponent, ColumnSearchFilterComponent,
        StandardDialogComponent, CustomDialogComponent, FilterComponent,
        LoadingComponent, FiltrationBaseComponent,
        MatSelectWithScrollComponent, SelectAddressOnMapComponent, SettingsComponent,
        SettingsBaseComponent, MatRangeDatepickerComponent,
        ClearValueComponent,
        DialogDeleteComponent,
        QuickFilterComponent,
        TitleContainerComponent,
        StarRatingComponent,
        StarSvgComponent,
        StatusProgressBarComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        FormAttributesModule,
        GoogleMapsModule,
        ReactiveFormsModule,
        NgxMatTimepickerModule,
        MatIconModule, MatChipsModule, TranslateModule, MatPaginatorModule, MatTooltipModule, MatSlideToggleModule, MatMenuModule, DragDropModule,
        NgxMatMomentModule,
        NgxMatDatetimePickerModule, DirectivesModule,
    ],
    exports: [
        FormAttributesModule, GoogleMapsModule,
        TableComponent, ContainerTitleAndToolbarComponent,
        LoadingComponent, NgxMatDatetimePickerModule,
        FiltrationBaseComponent, MatSelectWithScrollComponent,
        SelectAddressOnMapComponent, FilterComponent, MatRangeDatepickerComponent,
        ClearValueComponent, TitleContainerComponent, NgxMatTimepickerModule,
        MatIconModule,
        StarRatingComponent, StarSvgComponent,StatusProgressBarComponent
    ],
    providers: [
        {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
        {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
        {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter},
    ]
})
export class ComponentsModule {
}

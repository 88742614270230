import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'hasKeys',
    pure: false
})
export class HasKeysPipe implements PipeTransform {
    fastFilterKeys = ['orderStatuses', 'endDeliverOnDate', 'startDeliverOnDate'];

    transform(value: any): any {
        let keys: any = []
        for (let key in value) {
            if (value[key]) {
                if(key == 'shopIds'){
                    continue;
                }
                keys.push(key)
                keys = keys.filter((item: any) => this.fastFilterKeys.indexOf(item) === -1);
            }
        }
        return !!keys.length
    }
}

import {ControlValueAccessor, FormControlName, NgControl} from "@angular/forms";
import {inject, Injectable} from "@angular/core";

class NoopValueAccessor implements ControlValueAccessor {
    writeValue() {}
    registerOnChange() {}

    registerOnTouched() {}
}

@Injectable({
    providedIn: 'root'
})

export class InputService {
    injectNgControl(): any {
        const ngControl = inject(NgControl, {self: true, optional: true});

        if (!ngControl) throw new Error('...');

        if (
            ngControl instanceof FormControlName
        ) {
            ngControl.valueAccessor = new NoopValueAccessor();
            return ngControl;
        }

        throw new Error(`...`);
    }
}
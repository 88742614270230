export const environment = {
  production: true,
  yandexMapKey: '46ab6c8a-2b67-4118-a8a1-a15f77792894',
  publicLink: 'https://dev-api.click-market.ru',
  dataEndpoint: 'https://dev-api.click-market.ru/api/v1',
  // dataEndpoint: 'http://192.168.1.34:8080/api/v1',
  recaptcha: {
    siteKey: '6Lfc_YcpAAAAAAu_pQXMylit7RDBG3h5oSwDGH4A',
  }
};

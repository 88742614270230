import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    constructor(private router: Router, private location: Location) {
    }

    navigateToRelativeRoute(route = '', params = ''): void {
        if (route && params) {
            this.router.navigate([this.router.routerState.snapshot.url + '/' + route + '/' + params])
        } else if (route) {
            this.router.navigate([this.router.routerState.snapshot.url + '/' + route])
        } else if (params) {
            this.router.navigate([this.router.routerState.snapshot.url + '/' + params])
        }
    }

    navigateBack(): void {
        this.location.back();
    }



}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inArray'
})
export class InArrayPipe implements PipeTransform {

  transform(value: string | number, array: Array<any>, byKey: string = ''): boolean {
    if (byKey) {
      return Boolean(array.find((item: any) => item[byKey] === value))
    } else  {
      return array.includes(value);
    }
  }

}

<div *ngIf="inputData && ngControl" class="app_input">
    <ng-container *ngIf="inputData.type !== 'textarea' ">
        <app-password-eye *ngIf="inputData.type == 'password'" [input]="inputElement"></app-password-eye>
        <input #inputElement
               [type]="inputData.type"
               [ngClass]="{'with_left_icon' : inputData.leftIcon}"
               [readOnly]="inputData.readOnly"
               [attr.disabled]="inputData.disabled || null"
               [placeholder]="(inputData.placeholder || '') | translate"
               [prefix]="inputData.prefix || ''"
               [mask]="inputData.mask || ''"
               [formControl]="ngControl.control"
        >
    </ng-container>

    <ng-container *ngIf="inputData.type == 'textarea'">
        <textarea
                [ngClass]="{'with_left_icon' : inputData.leftIcon}"
                [readOnly]="inputData.readOnly"
                [attr.disabled]="inputData.disabled || null"
                [placeholder]="(inputData.placeholder || '') | translate"
                [prefix]="inputData.prefix || ''"
                [mask]="inputData.mask || ''"
                [formControl]="ngControl.control"></textarea>
    </ng-container>

    <app-error-message [message]="errorBody?.message"></app-error-message>

    <div *ngIf="inputData.leftIcon" class="input_icon left_icon_with_border flex_center_align_center">
        <img [src]="'assets/images/icon/input/' + inputData.leftIcon + '.svg'">
    </div>


    <div *ngIf="inputData.rightIcon" class="input_icon right_icon flex_center_align_center">
        <img [src]="'assets/images/icon/input/' + inputData.rightIcon + '.svg'">
    </div>


</div>


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addFieldToObject'
})
export class AddFieldToObjectPipe implements PipeTransform {

  transform(object: Object, newItem: Object): Object {
    return {...object, ...newItem};
  }

}

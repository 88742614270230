<svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="star"
          d="M13.924 2.18083C14.2697 1.48042 14.4426 1.13022 14.6772 1.01833C14.8814 0.920975 15.1186 0.920975 15.3228 1.01833C15.5575 1.13022 15.7303 1.48042 16.0761 2.18083L19.3561 8.82578C19.4582 9.03256 19.5092 9.13594 19.5838 9.21622C19.6498 9.28729 19.729 9.34488 19.817 9.38579C19.9163 9.43199 20.0304 9.44866 20.2586 9.48201L27.5955 10.5544C28.3681 10.6673 28.7544 10.7238 28.9332 10.9125C29.0887 11.0767 29.1619 11.3023 29.1323 11.5265C29.0982 11.7842 28.8186 12.0566 28.2592 12.6014L22.9522 17.7705C22.7867 17.9316 22.704 18.0122 22.6506 18.108C22.6034 18.1929 22.5731 18.2862 22.5614 18.3826C22.5482 18.4915 22.5677 18.6053 22.6067 18.833L23.8589 26.134C23.991 26.9041 24.0571 27.2891 23.9329 27.5176C23.825 27.7164 23.633 27.8558 23.4106 27.8971C23.1549 27.9445 22.8092 27.7626 22.1177 27.399L15.5586 23.9496C15.3542 23.8421 15.252 23.7884 15.1444 23.7673C15.049 23.7486 14.951 23.7486 14.8557 23.7673C14.748 23.7884 14.6459 23.8421 14.4415 23.9496L7.88239 27.399C7.19087 27.7626 6.84512 27.9445 6.58946 27.8971C6.36702 27.8558 6.17508 27.7164 6.06711 27.5176C5.943 27.2891 6.00904 26.9041 6.14111 26.134L7.39333 18.833C7.43237 18.6053 7.45189 18.4915 7.43868 18.3826C7.42699 18.2862 7.39667 18.1929 7.34941 18.108C7.29603 18.0122 7.21331 17.9316 7.04788 17.7705L1.74084 12.6014C1.18149 12.0566 0.901822 11.7842 0.867789 11.5265C0.838179 11.3023 0.91133 11.0767 1.06688 10.9125C1.24565 10.7238 1.63196 10.6673 2.40457 10.5544L9.74147 9.48201C9.96964 9.44866 10.0837 9.43199 10.1831 9.38579C10.271 9.34488 10.3502 9.28729 10.4163 9.21622C10.4909 9.13594 10.5419 9.03256 10.644 8.82578L13.924 2.18083Z"
          fill="url(#yellowGradient)"/>
    <defs>
        <linearGradient id="yellowGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop [attr.offset]="percentage + '%'" stop-color="#FF943D"/>
            <stop [attr.offset]="percentage + '%'" stop-color="#979797"/>
        </linearGradient>
    </defs>
</svg>
import { Pipe, PipeTransform } from '@angular/core';
import {TableData} from "../../../intrerfaces/user/components/table-component.interface";

@Pipe({
  name: 'cloneTableDataConfigsObjectAndSetNewDada'
})
export class CloneTableDataConfigsObjectAndSetNewDadaPipe implements PipeTransform {

  transform(dataConfigs: TableData | null, data: Array<{[key: string]: any}>): TableData | null {
    if (!dataConfigs) { return null; }
    const newConfigs = JSON.parse(JSON.stringify(dataConfigs));
    newConfigs.body = data;
    return newConfigs;
  }

}

import {animate, state, style, transition, trigger} from "@angular/animations";

export const slideRightFilterComponentAnimation = trigger('slideRightFilterComponentAnimation', [
    state('true', style({
        transform: 'translateX(0)',
    })),
    state('false', style({
        transform: 'translateX(100%)',
    })),
    transition('false <=> true', [
        animate('100ms 50ms ease-in')
    ])
])

export const openFilterComponentAnimation = trigger('openFilterComponentAnimation', [
    state('true', style({
        opacity: 1,
        visibility:'visible'
    })),
    state('false', style({
        opacity: 0,
        visibility:'hidden'
    })),
    transition('false <=> true', [
        animate('200ms 100ms ease-in')
    ])
])


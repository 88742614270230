<div class="app_input with_field_name relative" [formGroup]="dateRangeForm" >
    <span class="normal_text span_color">
        {{label | translate}}
    </span>
    <mat-form-field appearance="outline" class="w-100">
        <mat-date-range-input [rangePicker]="picker">
            <input class="unset" formControlName="startDate"
                   [title]="key ? key.title :  'Date'"
                   [accessKey]="key ? key.start : 'minStartDate'"
                   matStartDate #min
                   (keyup)="keyup($event, 'startDate')"
                   (dateChange)="selectionChange.emit([min.value, max.value || null]);checkValidation($event,'startDate')">
            <input class="unset" formControlName="endDate"
                   [title]="key ? key.title :  'Date'"
                   [accessKey]="key ? key.end : 'maxEndDate'"
                   matEndDate #max
                   (keyup)="keyup($event, 'endDate')"
                   (dateChange)="selectionChange.emit([min.value, max.value || null]);checkValidation($event,'endDate')">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <app-clear-value (reset)="selectionChange.emit([null,null])"></app-clear-value>
    <!--    <app-error-message *ngIf="submit && (!startDateValidation || !endDateValidation)" [message]="'Invalid Date'"></app-error-message>-->
</div>
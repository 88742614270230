import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {GoogleMap} from "@angular/google-maps";
import {MatDialogRef} from "@angular/material/dialog";
// Services
import {RequestMethodsService} from "@services/request/request-methods.service";
// Constants

@Component({
  selector: 'app-select-address-on-map',
  templateUrl: './select-address-on-map.component.html',
  styleUrls: ['./select-address-on-map.component.scss']
})
export class SelectAddressOnMapComponent {
  @ViewChild('map') map!: GoogleMap;
  @ViewChild('mapSearchField') mapSearchField!: ElementRef;
  apiLoaded = false;

  mapConfiguration = {
    fullscreenControl: true,
    zoomControl: true,
    currentAddress: true
  }
  constructor(
      private requestService: RequestMethodsService,
      private dialogRef: MatDialogRef<any>
  ) { }


}

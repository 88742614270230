import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {timeout} from "rxjs/operators";

//Constants
import {RequestMethodRoleEnum} from "@intrerfaces/user/request-methods/request-method-role.enum";

@Injectable({
    providedIn: 'root'
})
export class RequestMethodsService {

    constructor(private http: HttpClient) {
    }

    get(endpoint: string, role: RequestMethodRoleEnum = RequestMethodRoleEnum.admin): Observable<any> {
        return this.http.get(environment.dataEndpoint + (role==RequestMethodRoleEnum.global ?  '/' : `/${role}/`) + endpoint);
    }

    post(endpoint: string, data: any, role: RequestMethodRoleEnum = RequestMethodRoleEnum.admin, headers: any = {}): Observable<any> {
        return this.http.post(environment.dataEndpoint + (role==RequestMethodRoleEnum.global ?  '/' : `/${role}/`)  + endpoint, data, {headers});
    }

    put(endpoint: string, data: any, role: RequestMethodRoleEnum = RequestMethodRoleEnum.admin, headers: any = {'Access-Control-Allow-Origin': '*'}): Observable<any> {
        return this.http.put(environment.dataEndpoint + (role ? `/${role}/`: '/') + endpoint, data, {headers});
    }

    patch(endpoint: string, data: any, role: RequestMethodRoleEnum = RequestMethodRoleEnum.admin, headers: any = {'Access-Control-Allow-Origin': '*'}): Observable<any> {
        return this.http.patch(environment.dataEndpoint + (role ? `/${role}/`: '/') + endpoint, data, {headers});
    }

    delete(endpoint: string, role: RequestMethodRoleEnum = RequestMethodRoleEnum.admin): Observable<any> {
        return this.http.delete(environment.dataEndpoint + (role ? `/${role}/`: '/') + endpoint);
    }

    getAnotherDomain(url: string): Observable<any> {
        return this.http.get(url);
    }

    upload(endpoint: string, data: any, role: RequestMethodRoleEnum, headersPut: any = null, type: 'GET' | 'POST' = 'POST', timeoutValue = 24000000,): Observable<any> {
        const headers: any = {};
        if (headersPut && headersPut.accept) {
            headers.Accept  = headersPut.accept;
        }
        const option: any = {headers};
        if (headersPut && headersPut.responseType) {
            option.responseType = headersPut.responseType;
        }
        // this.createAuthorizationHeader(headers);
        let request;
        if (type ==='GET') {
            request = this.http.get(environment.dataEndpoint + (role==RequestMethodRoleEnum.global ?  '/' : `/${role}/`)  + endpoint, option)
        } else {
            request = this.http.post(environment.dataEndpoint + (role==RequestMethodRoleEnum.global ?  '/' : `/${role}/`)  + endpoint, data, option)
        }
        return request.pipe(timeout(timeoutValue));
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import {ParseDateService} from "../../../services/date/parse-date.service";

@Pipe({
  name: 'toLocaleDate'
})
export class ToLocaleDatePipe implements PipeTransform {

  constructor(
      private dateParseService: ParseDateService
  ) {}

  transform(
      date: string | Date,
      format: string = '',
      changeTimeZoneSuccess = true
  ): string {
    if(!date) {return '---'}
    const datTime = !changeTimeZoneSuccess ? this.dateParseService.changeFormat(date,format) : this.dateParseService.changeTimeZone(date, format);
    return (datTime !== 'Invalid date') ? datTime : '';
  }

}

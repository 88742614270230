import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: []
})
export class CustomDialogComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: {
        component: any,
        configs?: { title: string, description?: string, data?: any }
      }
  ) { }

  ngOnInit(): void {
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inString'
})
export class InStringPipe implements PipeTransform {

  transform(string: string, key: string): unknown {
    return string.includes(key);
  }

}

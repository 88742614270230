import {Component, EventEmitter, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-reason-note-dialog',
    templateUrl: './reason-note-dialog.component.html',
    styleUrls: ['./reason-note-dialog.component.scss']
})
export class ReasonNoteDialogComponent {
    @Output() closeModal = new EventEmitter<any>();
    submit = false;
    form = new UntypedFormGroup({
        reason: new UntypedFormControl('', Validators.required),
    })

    errorBody: { [key: string]: { message: string } } = {
        name: {message: ''},
    }

    save(): void {
        this.submit = true;
        if (this.form.invalid && this.submit) {
            return;
        }
        this.closeModal.emit(this.form.value.reason)
    }

}

<div class="app_page_top_bar text-title"
     [ngClass]="(tools.length) ? 'flex_between_align_center': 'flex_between'"
>
    <div class="app_page_top_bar--title-and-description-container">
        <div class="app_page_top_bar--title pointer">
            <ng-container *ngFor="let title of titles; let i = index">
            <span [ngClass]="((i + 1) < titles.length) ? 'app_page_top_bar--title--gray' : ''"
                  (click)="navigateBack(title.back || '')">
                     {{title.text | translate}}
            </span>
                <span class="app_page_top_bar--title--slash" *ngIf="(i + 1) < titles.length">/</span>
            </ng-container>
        </div>
        <div *ngIf="description" class="default_card--description">
            {{description | translate}}
        </div>
    </div>
    <div class="app_page_top_bar--right_side_tools flex_between" *ngIf="tools.length">
        <app-button-with-loading
                *ngFor="let tool of tools"
                (click)="buttonClickEvent.emit(tool.type)"
                [buttonClass]="'main_buttons ' + tool.class"
                [buttonStyle]="{'min-width': tool.name.length*15 + 'px' }"
                [buttonText]="tool.name"
                [submit]="tool.pending || false">
        </app-button-with-loading>
    </div>
</div>
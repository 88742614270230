import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
// Constants
import {TableConfigs, TableData} from "@intrerfaces/user/components/table-component.interface";

@Component({
  selector: 'app-table-column-sorting',
  templateUrl: './column-sorting.component.html',
  styleUrls: []
})
export class ColumnSortingComponent implements OnChanges {
  @Input() info: {
    config: TableConfigs | null,
    data: TableData | null,
    key: string
  } = {
    config: null,
    data: null,
    key: ''
  }
  @Input() changedDirection: string = '';

  accessToShowSortArrows = false;
  direction: 'ASC' | 'DESC' | null = null;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    const settings = this.info.data?.settings;
    if (!(changes.changedDirection && settings && settings.sorting && settings.sorting[this.info.key])) { return; }

    const pagination = this.info.data?.pagination;
    if (this.info.config?.actions?.sorting) {
      if ( pagination?.sortEnum === settings.sorting[this.info.key] ) {
        this.direction = pagination.sortDirection || null;
        this.accessToShowSortArrows = true;
      } else {
        this.clear();
      }
    }
  }

  clear(): void {
    this.direction = null;
    this.accessToShowSortArrows = false;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToString'
})
export class ArrayToStringPipe implements PipeTransform {

  transform(value: Array<string | number> | string): string {
    return (typeof value === 'string') ? value : value.join(',');
  }

}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-container-title-and-toolbar',
    templateUrl: './container-title-and-toolbar.component.html',
    styleUrls: ['./container-title-and-toolbar.component.scss']
})
export class ContainerTitleAndToolbarComponent {

    constructor(private router: Router) {
    }

    @Input() titles: Array<{ text: string, back?: string }> = [];
    @Input() fromDialog!: boolean
    @Input() description!: string;
    @Input() tools: Array<{ name: string, class: string, type: string, pending?: boolean }> = [];
    @Output() buttonClickEvent = new EventEmitter<any>();

    navigateBack(url: string): void {
        if (url) {
            this.router.navigate([url])
        }
    }
}

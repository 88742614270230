import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {endpointsConstant} from "@intrerfaces/global/endpoints.constant";
import {MatDialogRef} from "@angular/material/dialog";
import {slideRightFilterComponentAnimation} from "../../../../../../../animations/filter_dropdown.animation";

@Component({
  selector: 'app-table-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations:[slideRightFilterComponentAnimation]
})
export class SettingsComponent {
  @Input() data!: {settingsBody: any };
  @Input() passData!: boolean;
  @Output() closeModal = new EventEmitter<void>();
  ready!: boolean;
  checked: boolean = true;

  constructor() { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ready = true;
      this.data.settingsBody = this.data.settingsBody.filter((item:any) => item.name)
    },10)
  }

  saveState(index: number, event: any): void {
    this.data.settingsBody[index]['status'] = event.checked
  }

  close(): void{
    this.closeModal.emit();
  }
}

import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
//Constants
import {AppInputInterface} from "@intrerfaces/global/app-input.interface";
//Services
import {InputService} from "@services/components/input.service";

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements AfterViewInit {
    @Input() inputData!: AppInputInterface;
    @Input() detectChanges!: boolean;
    @Input('errorBody') set getErrorMessage(errorBody: { message: string }) {
        if (!errorBody) {
            return;
        }
        this.errorBody = errorBody
    };
    @Output() selectionChanges = new EventEmitter<any>();
    @ViewChild('inputElement') inputElement!: ElementRef;

    errorBody!: any
    ngControl: any;

    constructor(private inputService: InputService) {
        this.ngControl = this.inputService.injectNgControl();
    }

    ngAfterViewInit(): void {
        if (this.detectChanges) {
            this.inputElement?.nativeElement?.addEventListener('input', (event: any) => {
                this.selectionChanges.emit(event.target.value);
            })
        }
    }
}

import {Pipe, PipeTransform} from '@angular/core';
//services
import {ParseDateService} from "@services/date/parse-date.service";

@Pipe({
    name: 'ruTimePipe'
})
export class RuTimePipe implements PipeTransform {

    constructor(private dateParseService: ParseDateService
    ) {
    }

    transform(
        date: string | Date,
        format: string = '',
        parseZone = false,
        dontChangeOffset = false
    ): string {
        const datTime = this.dateParseService.toLocalTime(date, format, dontChangeOffset);
        return (datTime !== 'Invalid date') ? datTime : '';
    }
}
